import {
    HOME_CONST,
    PAGE_CONST,
    PROFILE_CONST
} from '../../common/constant/page-constant'
import { HEADER_CONFIG } from '../../common/constant/common-constant'

const initialState = {
    configReducer: {
        config: {
            activeHomeDetailNavigation: HOME_CONST.PRODUCT_RECEIVING,
            activeProfileDetailNavigation: PROFILE_CONST.PERSONAL_DETAILS,
            isNotificationActive: false
        }
    },
    alertReducer: {
        alert: {
            addProductReceivingAlert: false,
            addUserAlert: false,
            deleteUserAlert: false,
            addBranchAlert: false,
            addCustomerAlert: false,
            addBranchAlert: false,
            addDamageLossAlert: false,
            createSalesAlert: false,
            holdSalesAlert: false,
            updateSalesAlert: false,
            updateSalesByCustomerAlert: false,
            createExchangeReturnAlert: false,
            addOrderBookAlert: false,
            addSingleProductDiscountAlert: false,
            addCampaignDiscountAlert: false,
            updateCampaignDiscountAlert: false,
            newSalesAlert: false,
            addReminderAlert: false,
            deleteReminderAlert: false
        }
    },
    loginDataReducer: {
        loginData: {}
    },
    dataReducer: {
        salesData: [],
        exchangeReturnData: []
    }
}

export default initialState
