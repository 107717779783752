import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { redirect } from '../../common/utils/redirectionUtils'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import {
    PAGE_CONST,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import Header from '../Common/Header/Header'
import MobileHeader from '../Common/Header/MobileHeader'
import SideMenu from '../Common/SideMenu/SideMenu'

import './Settings.scss'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import AddIcon from '../../assets/AddIcon'
import {
    addReminderAlertAction,
    deleteReminderAlertAction
} from '../../modules/actions/action'
import Alert from '../../components/Alert/Alert'
import {
    addReminderHelper,
    deleteReminderHelper,
    getReminderHelper
} from '../../helper/query-helper'
import { convertToTitleCase, formatDate } from '../../common/utils/appUtils'
import CloseIcon from '../../assets/CloseIcon'
import DotsIcon from '../../assets/DotsIcon'

const Settings = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.SETTINGS,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [addReminderAlert, setAddReminderAlert] = useState(false)
    const [deleteReminderAlert, setDeleteReminderAlert] = useState(false)
    const [reminderList, setReminderList] = useState([])
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [repeat, setRepeat] = useState(undefined)
    const [priority, setPriority] = useState('')
    const [permissions, setPermissions] = useState({})
    const [isErrorFieldVisible, setIsErrorFieldVisible] = useState(false)
    const [isCardActive, setIsCardActive] = useState(undefined)

    useEffect(() => {
        let addReminderAlert = props.alert?.addReminderAlert
        setAddReminderAlert(addReminderAlert)

        let deleteReminderAlert = props.alert?.deleteReminderAlert
        setDeleteReminderAlert(deleteReminderAlert)
    }, [props.alert?.addReminderAlert, props.alert?.deleteReminderAlert])

    useEffect(() => {
        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission => permission.screen === PERMISSION_SCREEN.SETTINGS
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    useEffect(() => {
        getReminderData()
    }, [])

    const getReminderData = async () => {
        try {
            let rData = await getReminderHelper()

            setReminderList(rData)
        } catch (err) {
            console.log('error in reminder:: ', err)
        }
    }

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleSubmit = async e => {
        e?.preventDefault()

        if (
            !title ||
            !date ||
            !repeat?.value
            // || !priority
        ) {
            setIsErrorFieldVisible(true)
            return
        }

        setIsErrorFieldVisible(false)

        try {
            let data = {
                title: title,
                repeat: repeat?.value,
                date: date
                // priority: priority
            }
            let res = await addReminderHelper(data)

            resetData()

            props.addReminderAlertAction(true)

            setTimeout(() => {
                props.addReminderAlertAction(false)
            }, 5000)

            getReminderData()
        } catch (err) {}
    }

    const onCardAction = async (id, action) => {
        console.log('id: ', id)

        if (action === 'DELETE') {
            try {
                let res = await deleteReminderHelper(id)

                props.deleteReminderAlertAction(true)

                setTimeout(() => {
                    props.deleteReminderAlertAction(false)
                }, 5000)

                getReminderData()
            } catch (err) {
                console.log('error: ', err)
            }
        }
    }

    const resetData = () => {
        setTitle('')
        setRepeat('')
        setDate('')
        setPriority('')
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {!isMobile && <Header />}
            {addReminderAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage="Reminder added Successfully!"
                />
            )}
            {deleteReminderAlert && (
                <Alert
                    success
                    alertType="success"
                    alertMessage="Reminder deleted Successfully!"
                />
            )}
            <SideMenu
                activeItem={PAGE_CONST.SETTINGS}
                component={PAGE_CONST.SETTINGS}
            />
            <div className="pageBody">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                        <button
                            className="animated zoomIn btn btnPrimary"
                            onClick={handleDrawerToggle}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter webVisible mb-5">
                    <div className="pageBodyRightPanelHeading">
                        Reminder Settings
                    </div>
                </div>
                <div className="pageBodyPanelWrap dFlex">
                    <div className="pageBodyLeftPanel">
                        {reminderList?.map(reminder => {
                            return (
                                <div className="card helpCard">
                                    <div className="cardHeader">
                                        <div className="cardHeaderDetails">
                                            <small>{reminder?.priority}</small>
                                            <div className="helpIssue">
                                                {reminder?.title}
                                            </div>
                                        </div>
                                        <div className="cardHeaderRight">
                                            {isCardActive === reminder?.id ? (
                                                <span
                                                    onClick={() =>
                                                        setIsCardActive(
                                                            undefined
                                                        )
                                                    }>
                                                    <CloseIcon />
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() =>
                                                        setIsCardActive(
                                                            reminder?.id
                                                        )
                                                    }>
                                                    <DotsIcon />
                                                </span>
                                            )}
                                            <ul
                                                className={`cardActionsWrapper ${
                                                    isCardActive ===
                                                    reminder?.id
                                                        ? 'show'
                                                        : 'hide'
                                                }`}>
                                                {['DELETE']?.map(action => {
                                                    return (
                                                        <li
                                                            className="actionItem"
                                                            onClick={() => {
                                                                setIsCardActive(
                                                                    false
                                                                )
                                                                onCardAction(
                                                                    reminder?.id,
                                                                    action
                                                                )
                                                            }}>
                                                            {action}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="cardFooter">
                                        <ul className="helpDuration dFlex">
                                            <li className="duration">
                                                {formatDate(
                                                    reminder?.created_at
                                                )}
                                            </li>
                                            <li className="duration">
                                                {convertToTitleCase(
                                                    reminder?.repeat
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {!isMobile &&
                        permissions?.features?.[
                            PERMISSION_FEATURE.REMINDER_SETTINGS
                        ] === PERMISSION_TYPE.MANAGE && (
                            <div className="pageBodyRightPanel">
                                <form>
                                    <div className="panelHeading">
                                        Add Reminder
                                    </div>
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="invoice"
                                            placeholder="Reminder Title"
                                            value={title}
                                            onChange={e =>
                                                setTitle(e?.target?.value)
                                            }
                                            required
                                        />
                                        <label for="invoice">
                                            Reminder Title
                                        </label>
                                        {isErrorFieldVisible && !title && (
                                            <div class="invalidFeedback">
                                                Please select a valid Reminder
                                                Title.
                                            </div>
                                        )}
                                    </div>
                                    <div className="panelHeading">Details</div>
                                    <div className="formGroup formFloating fgrow1">
                                        <input
                                            type="date"
                                            className="formControl"
                                            id="invoice"
                                            placeholder="Date"
                                            value={date}
                                            onChange={e =>
                                                setDate(e?.target?.value)
                                            }
                                            required
                                        />
                                        <label for="invoice">Date</label>
                                        {isErrorFieldVisible && !date && (
                                            <div class="invalidFeedback">
                                                Please select a valid Date.
                                            </div>
                                        )}
                                    </div>
                                    <div className="formGroup formFloating fgrow1 maxWidth">
                                        <Select
                                            className="formSelect reactSelect"
                                            id="floatingSelect"
                                            value={repeat}
                                            options={[
                                                {
                                                    label: 'Does Not Repeat',
                                                    value: 'DOES_NOT_REPEAT'
                                                },
                                                {
                                                    label: 'Daily',
                                                    value: 'DAILY'
                                                },
                                                {
                                                    label: 'Weekly',
                                                    value: 'WEEKLY'
                                                },
                                                {
                                                    label: 'Monthly',
                                                    value: 'MONTHLY'
                                                }
                                            ]}
                                            onChange={option =>
                                                setRepeat(option)
                                            }
                                            placeholder="Select Repeat"
                                            styles={{
                                                control: base => ({
                                                    border: 0,
                                                    display: 'flex',
                                                    paddingTop: '20px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor:
                                                        state.isFocused
                                                            ? 'rgb(222, 235, 255)'
                                                            : state.isSelected
                                                            ? '#FFF'
                                                            : 'inherit',
                                                    color: '#212529',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            state.isSelected
                                                                ? '#DEEBFF'
                                                                : 'rgb(222, 235, 255)'
                                                    }
                                                })
                                            }}
                                            getOptionLabel={option =>
                                                option?.label
                                            }
                                            getOptionValue={option =>
                                                option?.value
                                            }
                                            isClearable
                                        />
                                        {/* <select
                                            className="formSelect"
                                            id="floatingSelect">
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select> */}
                                        <label for="floatingSelect">
                                            Repeat
                                        </label>
                                        {isErrorFieldVisible && !repeat && (
                                            <div class="invalidFeedback">
                                                Please choose Repeat
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className="panelHeading">Priority</div> */}
                                    {/* <div className="formSection dFlex mb-5">
                                        <div class="formCheck">
                                            <input
                                                class="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="High"
                                                checked={priority === 'HIGH'}
                                                onChange={e =>
                                                    setPriority('HIGH')
                                                }
                                            />
                                            <label
                                                class="formCheckLabel"
                                                for="High">
                                                High
                                            </label>
                                        </div>
                                        <div class="formCheck">
                                            <input
                                                class="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="medium"
                                                checked={priority === 'MEDIUM'}
                                                onChange={e =>
                                                    setPriority('MEDIUM')
                                                }
                                            />
                                            <label
                                                class="formCheckLabel"
                                                for="medium">
                                                Medium
                                            </label>
                                        </div>
                                        <div class="formCheck">
                                            <input
                                                class="formCheckInput"
                                                type="radio"
                                                name="flexRadioDefault"
                                                id="low"
                                                checked={priority === 'LOW'}
                                                onChange={e =>
                                                    setPriority('LOW')
                                                }
                                            />
                                            <label
                                                class="formCheckLabel"
                                                for="low">
                                                Low
                                            </label>
                                        </div>
                                        {isErrorFieldVisible && !priority && (
                                            <div class="invalidFeedback">
                                                Please select Reminder Priority.
                                            </div>
                                        )}
                                    </div> */}
                                    <div className="actionRow">
                                        <button
                                            className="btn btnPrimary block"
                                            onClick={e => handleSubmit(e)}>
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                </div>
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={
                        open &&
                        permissions?.features?.[
                            PERMISSION_FEATURE.REMINDER_SETTINGS
                        ] === PERMISSION_TYPE.MANAGE
                    }
                    drawerButton={true}
                    drawerHeading="Add Reminder"
                    mwebSideDrawerFooter>
                    <form>
                        <div className="formGroup formFloating fgrow1">
                            <input
                                type="text"
                                className="formControl"
                                id="invoice"
                                placeholder="Reminder Title"
                                required
                            />
                            <label for="invoice">Reminder Title</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please select a valid Reminder Title.
                                </div>
                            )}
                        </div>
                        <div className="panelHeading">Details</div>
                        <div className="formGroup formFloating fgrow1">
                            <input
                                type="date"
                                className="formControl"
                                id="invoice"
                                placeholder="Date"
                                // value={date}
                                required
                            />
                            <label for="invoice">Date</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please select a valid Date.
                                </div>
                            )}
                        </div>
                        <div className="formGroup formFloating fgrow1 maxWidth">
                            <select className="formSelect" id="floatingSelect">
                                <option value="1">Yes</option>
                                <option value="2">No</option>
                            </select>
                            <label for="floatingSelect">Repeat</label>
                            {false && (
                                <div class="invalidFeedback">
                                    Please choose Repeat
                                </div>
                            )}
                        </div>
                        <div className="panelHeading">Priority</div>
                        <div className="formSection dFlex mb-5">
                            <div class="formCheck">
                                <input
                                    class="formCheckInput"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="High"
                                />
                                <label class="formCheckLabel" for="High">
                                    High
                                </label>
                            </div>
                            <div class="formCheck">
                                <input
                                    class="formCheckInput"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="medium"
                                />
                                <label class="formCheckLabel" for="medium">
                                    Medium
                                </label>
                            </div>
                            <div class="formCheck">
                                <input
                                    class="formCheckInput"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="low"
                                />
                                <label class="formCheckLabel" for="low">
                                    Low
                                </label>
                            </div>
                            {false && (
                                <div class="invalidFeedback">
                                    Please select Product Category.
                                </div>
                            )}
                        </div>
                    </form>
                </SideDrawer>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        loginData: state.loginDataReducer?.loginData,
        alert: state.alertReducer.alert
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addReminderAlertAction: payload =>
            dispatch(addReminderAlertAction(payload)),
        deleteReminderAlertAction: payload =>
            dispatch(deleteReminderAlertAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
