import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactSelect from 'react-select'
import { redirect } from '../../common/utils/redirectionUtils'
import { connect } from 'react-redux'
import AddIcon from '../../assets/AddIcon'
import ArrowDown from '../../assets/ArrowDown'
import CloseIcon from '../../assets/CloseIcon'
import LocationIcon from '../../assets/LocationIcon'
import ProductReceivingBlack from '../../assets/ProductReceivingBlack'
import TrashIcon from '../../assets/TrashIcon'
import {
    HEADER_CONST,
    HEADER_CONFIG,
    WIZARD_STEPS
} from '../../common/constant/common-constant'
import {
    ADD_SALES_STAGES,
    CUSTOMER_FIELDS,
    ONLINE_SALES_STAGES,
    PAGE_CONST,
    PAYMENT_DETAILS_FIELDS,
    PAYMENT_MODE_OPTIONS,
    PERMISSION_FEATURE,
    PERMISSION_SCREEN,
    PERMISSION_TYPE,
    PRESCRIPTION_ORDER_MILESTONE,
    PRODUCT_DETAIL_FIELDS,
    SALES_CONST,
    SALES_FLOW,
    SALES_TYPE,
    SALES_TYPE_BACKEND_MAPPING
} from '../../common/constant/page-constant'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import WizardSteps from '../../components/WizardSteps/WizardSteps'
import Card from '../Common/Card/Card'
import MobileHeader from '../Common/Header/MobileHeader'
import SideDrawer from '../Common/SideDrawer/SideDrawer'

import './Sales.scss'
import ViewSales from './ViewSales'
import PendingImage from '../../assets/PendingImage'
import {
    addCustomerHelper,
    confirmOnlineSalesOrderHelper,
    createSalesHelper,
    getAllSalesOrderHelper,
    getCustomerHelper,
    getProductDetailsByBarcodeHelper,
    getSalesOrderHelper,
    updatePrescriptionOrderHelper,
    updateSalesHelper
} from '../../helper/home-helper'
import Product from './Product'
import PopupCard from '../Common/PopupCard/PopupCard'
import Alert from '../../components/Alert/Alert'
import {
    createSalesAlertAction,
    holdSalesAlertAction,
    newSalesDataAction,
    onlineSalesOrderCancellationAlertAction,
    onlineSalesOrderConfirmationAlertAction,
    salesDataAction,
    updateSalesAlertAction
} from '../../modules/actions/action'
import {
    convertToTitleCase,
    formatDate,
    formatTime
} from '../../common/utils/appUtils'
import AcknowledgeScreen from '../Common/AcknowledgeScreen/AcknowledgeScreen'
import CheckPrimaryIcon from '../../assets/CheckPrimaryIcon'
import { getDeliveryPartnersHelper } from '../../helper/personal-details-helper'
import { SocketContext } from '../../common/context/socket'
import Select from 'react-select'
import SearchIcon from '../../assets/SearchIcon'
import PhoneIcon from '../../assets/PhoneIcon'
import CallIcon from '../../assets/CallIcon'

const Sales = props => {
    const navigate = useNavigate()
    const isMobile = useCheckMobileScreen()
    const [offlineDrawerOpen, setOfflineDrawerOpen] = useState(false)
    const [onlineDrawerOpen, setOnlineDrawerOpen] = useState(false)
    const [onlinePrescriptionDrawerOpen, setOnlinePrescriptionDrawerOpen] =
        useState(false)
    const [wizardState, setWizardState] = useState(undefined)
    const [isViewSalesActive, setViewSalesActive] = useState(false)
    const [isFieldErrorVisible, setIsFieldErrorVisible] = useState(false)
    const [serverError, setServerError] = useState(undefined)
    const [deleteProductPopup, setDeleteProductPopup] = useState(false)
    const [addProductActive, setAddProductActive] = useState(false)
    const [createSalesAlert, setCreateSalesAlert] = useState(false)
    const [holdSalesAlert, setHoldSalesAlert] = useState(false)
    const [updateSalesAlert, setUpdateSalesAlert] = useState(false)
    const [onlineSalesConfirmationAlert, setOnlineSalesConfirmationAlert] =
        useState(false)
    const [onlineSalesCancellationAlert, setOnlineSalesCancellationAlert] =
        useState(false)
    const [addedProductVisibleId, setAddedProductDetailsId] = useState({
        id: undefined
    })
    const [activeSalesTab, setActiveSalesTab] = useState(SALES_TYPE.HOLD)
    const [headerOptions, setHeaderOptions] = useState({
        [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
        [HEADER_CONFIG.TITLE]: HEADER_CONST.SALES,
        [HEADER_CONFIG.HANDLE_BACK_FUNC]: () =>
            redirect(navigate, PAGE_CONST.HOME)
    })
    const [salesFlow, setSalesFlow] = useState(SALES_FLOW.OFFLINE)
    const [addSalesStage, setAddSalesStage] = useState(
        ADD_SALES_STAGES.CUSTOMER_DETAILS
    )
    const [onlineSalesStage, setOnlineSalesStage] = useState(
        ONLINE_SALES_STAGES.PRODUCT_DETAILS
    )

    const [allSalesData, setAllSalesData] = useState([])
    const [stats, setStats] = useState({})
    const [salesData, setSalesData] = useState({})
    const [customerDetails, setCustomerDetails] = useState(undefined)
    const [productDetails, setProductDetails] = useState({})
    const [productDetailsArray, setProductDetailsArray] = useState([])
    const [paymentDetails, setPaymentDetails] = useState(undefined)
    const [deliveryPartnerOptionList, setDeliveryPartnerOptionList] = useState(
        []
    )
    const [selectedDeliveryPartner, setSelectedDeliveryPartner] =
        useState(undefined)
    const [loginData, setLoginData] = useState({})
    const [permissions, setPermissions] = useState({})
    const inputRef = useRef()
    const [viewSearch, setViewSearch] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [allSalesFilteredData, setAllSalesFilteredData] =
        useState(allSalesData)

    useEffect(() => {
        if (props?.salesData) {
            setAllSalesData([...props.salesData])
            setAllSalesFilteredData([...props.salesData])
            console.log('Received message in sales page:', props.salesData)
        }
    }, [props.salesData])

    useEffect(() => {
        let asFilteredData = allSalesData.filter(sale =>
            sale?.customer_mobile_no?.includes(searchTerm)
        )

        setAllSalesFilteredData(asFilteredData)
    }, [searchTerm])

    useEffect(() => {
        if (
            !offlineDrawerOpen &&
            !onlineDrawerOpen &&
            !onlinePrescriptionDrawerOpen &&
            !isViewSalesActive
        ) {
            handleHeaderConfigUpdate(HEADER_CONST.SALES, () =>
                redirect(navigate, PAGE_CONST.HOME)
            )
            return
        }

        setAddProductActive(false)
        handleWizardState(WIZARD_STEPS.STEP_1)
        if (salesFlow === SALES_FLOW.OFFLINE_HOLD)
            setAddSalesStage(ADD_SALES_STAGES.PRODUCT_DETAILS)
        else setAddSalesStage(ADD_SALES_STAGES.CUSTOMER_DETAILS)

        if (offlineDrawerOpen) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_SALES, () =>
                handleDrawerToggle()
            )
        } else if (onlineDrawerOpen) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_SALES, () =>
                handleOnlineDrawerToggle()
            )
        } else if (onlinePrescriptionDrawerOpen) {
            handleHeaderConfigUpdate(HEADER_CONST.ADD_SALES, () =>
                handleOnlinePrescriptionDrawerToggle()
            )
        } else if (isViewSalesActive) {
            handleHeaderConfigUpdate(HEADER_CONST.VIEW_SALES, () =>
                setViewSalesActive(!isViewSalesActive)
            )
        }
    }, [
        offlineDrawerOpen,
        onlineDrawerOpen,
        onlinePrescriptionDrawerOpen,
        isViewSalesActive
    ])

    useEffect(() => {
        getAllSalesData()
    }, [])

    useEffect(() => {
        let createSalesAlert = props.alert?.createSalesAlert
        let holdSalesAlert = props.alert?.holdSalesAlert
        let updateSalesAlert = props.alert?.updateSalesAlert
        let onlineSalesConfirmationAlert =
            props.alert.onlineSalesConfirmationAlert
        let onlineSalesCancellationAlert =
            props.alert.onlineSalesCancellationAlert
        setCreateSalesAlert(createSalesAlert)
        setHoldSalesAlert(holdSalesAlert)
        setUpdateSalesAlert(updateSalesAlert)
        setOnlineSalesConfirmationAlert(onlineSalesConfirmationAlert)
        setOnlineSalesCancellationAlert(onlineSalesCancellationAlert)
    }, [
        props.alert?.createSalesAlert,
        props.alert?.holdSalesAlert,
        props.alert?.updateSalesAlert,
        props.alert?.onlineSalesConfirmationAlert,
        props.alert?.onlineSalesCancellationAlert
    ])

    useEffect(() => {
        setServerError(undefined)
        switch (addSalesStage) {
            case ADD_SALES_STAGES.CUSTOMER_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_1)
                break
            case ADD_SALES_STAGES.PRODUCT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_2)
                if (productDetailsArray.length <= 0) setAddProductActive(true)
                break
            case ADD_SALES_STAGES.PAYMENT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_3)
                handlePaymentDetailsUpdate(
                    PAYMENT_DETAILS_FIELDS.AMOUNT,
                    getSalesAmount()
                )
                break
        }
    }, [addSalesStage])

    useEffect(() => {
        setServerError(undefined)
        switch (onlineSalesStage) {
            case ONLINE_SALES_STAGES.PRODUCT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_1)
                break
            case ADD_SALES_STAGES.PAYMENT_DETAILS:
                handleWizardState(WIZARD_STEPS.STEP_2)
                handlePaymentDetailsUpdate(
                    PAYMENT_DETAILS_FIELDS.AMOUNT,
                    getSalesAmount()
                )
                break
        }
    }, [onlineSalesStage])

    useEffect(() => {
        let totalAmount = getTotalAmount()

        if (
            totalAmount != paymentDetails?.[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]
        ) {
            handlePaymentDetailsUpdate(
                PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT,
                getTotalAmount()
            )
        }
    }, [
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.VAT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT],
        paymentDetails?.[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE]
    ])

    useEffect(() => {
        setLoginData(props.loginData)

        if (props.loginData.permissions) {
            console.log(
                'props.loginData.permissions: ',
                props.loginData.permissions
            )

            let permission = props.loginData.permissions.filter(
                permission =>
                    permission.screen === PERMISSION_SCREEN.MANAGE_STORE
            )[0]

            console.log('home screen permission: ', permission)
            if (permission) setPermissions(permission)
        }
    }, [props.loginData])

    const expandSearch = () => {
        inputRef.current.focus(setViewSearch(true))
    }

    const getAllSalesData = () => {
        getAllSalesOrderHelper()
            .then(res => {
                let sData = res.sales_orders
                let stats = res.stats
                setStats(stats)
                props.dispatchSalesDataAction(sData)
                console.log('res aso: ', res)
            })
            .catch(err => {
                console.log('err aso: ', err)
            })
    }

    const getProductDetailsByBarcode = bCode => {
        setServerError('')
        let barCode =
            bCode || productDetails?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]
        if (!barCode) {
            return
        }
        getProductDetailsByBarcodeHelper(barCode)
            .then(res => {
                if (res) {
                    let data = {
                        ...res
                    }
                    setProductDetails(data)
                }
            })
            .catch(err => {
                setServerError(err)
                setProductDetails({
                    [PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE]: barCode
                })
                // console.log("")
            })
    }

    const handleHeaderConfigUpdate = (title, handleBackFunc) => {
        setHeaderOptions({
            [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
            [HEADER_CONFIG.TITLE]: title,
            [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => handleBackFunc()
        })
    }

    const handleDrawerToggle = () => {
        setOfflineDrawerOpen(!offlineDrawerOpen)
    }

    const handleOnlineDrawerToggle = () => {
        if (onlineDrawerOpen) resetDrawerData()
        setOnlineDrawerOpen(!onlineDrawerOpen)
    }

    const handleOnlinePrescriptionDrawerToggle = () => {
        if (onlinePrescriptionDrawerOpen) resetDrawerData()
        setOnlinePrescriptionDrawerOpen(!onlinePrescriptionDrawerOpen)
    }

    const handleWizardState = state => {
        switch (state) {
            case WIZARD_STEPS.STEP_3:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: true,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
            case WIZARD_STEPS.STEP_2:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: true,
                    [WIZARD_STEPS.STEP_2]: false,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
            case WIZARD_STEPS.STEP_1:
                setWizardState({
                    [WIZARD_STEPS.STEP_1]: false,
                    [WIZARD_STEPS.STEP_2]: false,
                    [WIZARD_STEPS.STEP_3]: false
                })
                break
        }
    }

    const onOnlineSalesWizardStepClick = step => {
        switch (step) {
            case WIZARD_STEPS.STEP_1:
                setOnlineSalesStage(ONLINE_SALES_STAGES.PRODUCT_DETAILS)
                break
            case WIZARD_STEPS.STEP_2:
                setOnlineSalesStage(ONLINE_SALES_STAGES.PAYMENT_DETAILS)
                break
        }
    }

    const onAddSalesWizardStepClick = step => {
        switch (step) {
            case WIZARD_STEPS.STEP_1:
                setAddSalesStage(ADD_SALES_STAGES.CUSTOMER_DETAILS)
                break
            case WIZARD_STEPS.STEP_2:
                setAddSalesStage(ADD_SALES_STAGES.PRODUCT_DETAILS)
                break
            case WIZARD_STEPS.STEP_3:
                setAddSalesStage(ADD_SALES_STAGES.PAYMENT_DETAILS)
                break
        }
    }

    const checkAddProductValidation = pDetails => {
        if (pDetails) {
            if (
                pDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] &&
                pDetails[PRODUCT_DETAIL_FIELDS.PRODUCT_UNDERSCORE_ID] &&
                pDetails[PRODUCT_DETAIL_FIELDS.QUANTITY] &&
                pDetails[PRODUCT_DETAIL_FIELDS.DOSE] &&
                parseInt(pDetails?.[PRODUCT_DETAIL_FIELDS.QUANTITY]) <=
                    parseInt(
                        pDetails?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                        ]?.[PRODUCT_DETAIL_FIELDS.STOCK]
                    )
            )
                return true
        }

        return false
    }

    const handleAddProductClick = () => {
        const isValidState = checkAddProductValidation(productDetails)
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        console.log('productDetails :: ', productDetails)
        let pdArray = productDetailsArray
        pdArray.push(productDetails)
        setProductDetailsArray(pdArray)
        setProductDetails({})
        setAddProductActive(false)
    }

    const handleRemoveProductClick = product => {
        let pdArray = [...productDetailsArray]

        const objWithIdIndex = pdArray.findIndex(
            obj => obj.product_id === product.product_id
        )

        if (objWithIdIndex > -1) {
            pdArray.splice(objWithIdIndex, 1)
        }

        setProductDetailsArray(pdArray)
        setDeleteProductPopup(false)
    }

    const handleProductDetailsUpdate = (id, key, value) => {
        setProductDetails(prevDetails => {
            return {
                ...prevDetails,
                [key]: value
            }
        })
    }

    const handleExistingProductUpdate = (id = undefined, key, value) => {
        let pDetails = productDetailsArray || []

        let productIndex = pDetails.findIndex(obj => obj.product_id == id)
        if (productIndex > -1) {
            pDetails[productIndex][key] = value
        } else {
            let newProduct = {
                id: Math.floor(Math.random() * 100),
                [key]: value
            }

            pDetails.push(newProduct)
        }

        setProductDetailsArray([...pDetails])
    }

    const checkValidation = () => {
        // return true;
        if (addSalesStage === ADD_SALES_STAGES.CUSTOMER_DETAILS) {
            if (customerDetails) {
                if (
                    customerDetails[CUSTOMER_FIELDS.NAME] &&
                    customerDetails[CUSTOMER_FIELDS.PHONE_NUMBER] &&
                    customerDetails[CUSTOMER_FIELDS.BILLING_ADDRESS] &&
                    customerDetails[CUSTOMER_FIELDS.SHIPPING_ADDRESS]
                ) {
                    return true
                }
            }

            return false
        } else if (addSalesStage === ADD_SALES_STAGES.PRODUCT_DETAILS) {
            if (productDetailsArray && productDetailsArray.length > 0) {
                let allValid = productDetailsArray.every(product => {
                    return checkAddProductValidation(product)
                })

                if (allValid) return true

                return false
            }
        } else if (addSalesStage === ADD_SALES_STAGES.PAYMENT_DETAILS) {
            if (paymentDetails) {
                if (
                    paymentDetails[PAYMENT_DETAILS_FIELDS.AMOUNT] &&
                    // paymentDetails[PAYMENT_DETAILS_FIELDS.DISCOUNT] &&
                    // && paymentDetails[PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT]
                    // paymentDetails[PAYMENT_DETAILS_FIELDS.VAT] &&
                    paymentDetails[PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT]

                    // && paymentDetails[PAYMENT_DETAILS_FIELDS.RECEIVED_BY]
                ) {
                    if (
                        paymentDetails[PAYMENT_DETAILS_FIELDS.PAYMENT_MODE] ===
                        'CASH_CARD'
                            ? parseFloat(
                                  paymentDetails[
                                      PAYMENT_DETAILS_FIELDS.AMOUNT_CARD
                                  ] || 0
                              ) +
                                  parseFloat(
                                      paymentDetails[
                                          PAYMENT_DETAILS_FIELDS.AMOUNT_CASH
                                      ] || 0
                                  ) ===
                              paymentDetails[
                                  PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT
                              ]
                            : true
                    ) {
                        return true
                    }
                }
            }

            return false
        }
    }

    const handleNextClick = async () => {
        const isValidState = checkValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)
        switch (addSalesStage) {
            case ADD_SALES_STAGES.CUSTOMER_DETAILS:
                console.log('customer Details: ', customerDetails)

                if (!customerDetails.id) {
                    try {
                        await addCustomer()
                    } catch (errMsg) {
                        setServerError(errMsg)
                        return
                    }
                }
                setAddSalesStage(ADD_SALES_STAGES.PRODUCT_DETAILS)
                break
            case ADD_SALES_STAGES.PRODUCT_DETAILS:
                console.log('Product Details: ', productDetailsArray)
                setAddSalesStage(ADD_SALES_STAGES.PAYMENT_DETAILS)
                break
            case ADD_SALES_STAGES.PAYMENT_DETAILS:
                if (salesFlow === SALES_FLOW.OFFLINE_HOLD) handleUpdateSales()
                else handleCreateSales()
                break
        }
    }

    const handleOnlineOrderNextClick = () => {
        if (onlineSalesStage === ONLINE_SALES_STAGES.PRODUCT_DETAILS) {
            setOnlineSalesStage(ONLINE_SALES_STAGES.PAYMENT_DETAILS)
            // handleWizardState(WIZARD_STEPS.STEP_2)

            // Uncomment this block to enable delivery partner selection
            // getDeliveryPartnerList()
        } else if (onlineSalesStage === ONLINE_SALES_STAGES.PAYMENT_DETAILS) {
            // Uncomment this block to enable delivery partner selection
            // if (!selectedDeliveryPartner) {
            //     setIsFieldErrorVisible(true)
            //     return
            // }

            confirmOnlineSalesOrder(SALES_TYPE.CONFIRMED)
        }
    }

    const handleOnlineOrderWithPrescriptionNextClick = () => {
        if (onlineSalesStage === ONLINE_SALES_STAGES.PRODUCT_DETAILS) {
            if (productDetailsArray?.length > 0) {
                setOnlineSalesStage(ONLINE_SALES_STAGES.PAYMENT_DETAILS)
            }
            // handleWizardState(WIZARD_STEPS.STEP_2)
            // getDeliveryPartnerList()
        } else if (onlineSalesStage === ONLINE_SALES_STAGES.PAYMENT_DETAILS) {
            let data = {
                productDetails: productDetailsArray,
                paymentDetails: paymentDetails,
                orderId: salesData?.id
            }

            console.log('sales with prescription data', data)

            updatePrescriptionOrderHelper(data)
                .then(res => {
                    console.log(res)

                    props.updateSalesAlertAction(true)
                    setTimeout(() => {
                        props.updateSalesAlertAction(false)
                    }, 5000)

                    getAllSalesData()
                    resetDrawerData()
                    handleOnlinePrescriptionDrawerToggle()
                })
                .catch(errMsg => {
                    setServerError(errMsg)
                })
        }
    }

    const confirmOnlineSalesOrder = status => {
        let data = {
            id: salesData.id,
            deliveryPartnerId:
                selectedDeliveryPartner && selectedDeliveryPartner.id,
            status: status
        }

        confirmOnlineSalesOrderHelper(data)
            .then(res => {
                console.log(res)

                if (status === SALES_TYPE.CANCELLED) {
                    props.onlineSalesOrderCancellationAlertAction(true)
                    setTimeout(() => {
                        props.onlineSalesOrderCancellationAlertAction(false)
                    }, 5000)
                } else {
                    props.onlineSalesOrderConfirmationAlertAction(true)
                    setTimeout(() => {
                        props.onlineSalesOrderConfirmationAlertAction(false)
                    }, 5000)
                }

                getAllSalesData()
                resetDrawerData()
                handleOnlineDrawerToggle()
            })
            .catch(err => {
                setServerError(err)
            })
    }

    const getDeliveryPartnerList = () => {
        getDeliveryPartnersHelper()
            .then(res => {
                console.log('dpol res: ', res)
                setDeliveryPartnerOptionList(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getCustomer = () => {
        if (
            ![10, 11].includes(
                customerDetails?.[CUSTOMER_FIELDS.PHONE_NUMBER]?.length
            )
        ) {
            return
        }

        getCustomerHelper(customerDetails)
            .then(res => {
                if (res) {
                    setCustomerDetails({
                        ...res
                    })
                }

                console.log(res)
            })
            .catch(() => {})
    }

    const addCustomer = async () => {
        let data = { ...customerDetails }

        try {
            let res = await addCustomerHelper(data)
            console.log(res)

            setCustomerDetails({
                ...customerDetails,
                id: res.id
            })
        } catch (errMsg) {
            throw errMsg
        }
    }

    const updateCustomerDetails = (type, value) => {
        let cDetails = customerDetails
        if (type === CUSTOMER_FIELDS.PHONE_NUMBER) {
            cDetails = {}
        }

        if (type === CUSTOMER_FIELDS.BILLING_ADDRESS) {
            cDetails[CUSTOMER_FIELDS.SHIPPING_ADDRESS] = value
        }

        setCustomerDetails({
            ...cDetails,
            [type]: value
        })
    }

    const handleHoldSales = () => {
        const isValidState = checkValidation()
        if (!isValidState) {
            setIsFieldErrorVisible(true)
            return
        }

        setIsFieldErrorVisible(false)

        handleCreateSales(SALES_TYPE.HOLD)
    }

    const handlePaymentDetailsUpdate = (key, value) => {
        setPaymentDetails(prevDetails => {
            let updatedDetails = { ...prevDetails }

            // Update the payment details based on the key and value
            if (key === PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT) {
                let discountPercentage = parseFloat(
                    (value / prevDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]) * 100
                ).toFixed(2)
                updatedDetails = {
                    ...updatedDetails,
                    [PAYMENT_DETAILS_FIELDS.DISCOUNT]: discountPercentage
                }
            } else if (key === PAYMENT_DETAILS_FIELDS.DISCOUNT) {
                let discountAmount = parseFloat(
                    (value / 100) * prevDetails[PAYMENT_DETAILS_FIELDS.AMOUNT]
                ).toFixed(2)
                updatedDetails = {
                    ...updatedDetails,
                    [PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT]: discountAmount
                }
            }

            // Finally, update the key that was passed in
            updatedDetails[key] = value
            console.log('Updated payment details:', updatedDetails)
            return updatedDetails
        })

        // setPaymentDetails({
        //     ...paymentDetails,
        //     [key]: value
        // })
    }

    const handleCreateSales = status => {
        let data = {
            customerDetails: customerDetails,
            productDetails: productDetailsArray,
            paymentDetails: paymentDetails
        }

        if (status) data.status = status

        console.log(data)
        // return;

        createSalesHelper(data)
            .then(res => {
                console.log(res)

                if (status === SALES_TYPE.HOLD) {
                    props.holdSalesAlertAction(true)
                    setTimeout(() => {
                        props.holdSalesAlertAction(false)
                    }, 5000)
                } else {
                    props.createSalesAlertAction(true)
                    setTimeout(() => {
                        props.createSalesAlertAction(false)
                    }, 5000)
                }

                getAllSalesData()
                resetDrawerData()
                handleDrawerToggle()
            })
            .catch(errMsg => {
                setServerError(errMsg)
            })
    }

    const handleUpdateSales = () => {
        let data = {
            id: salesData.id,
            productDetails: productDetailsArray,
            paymentDetails: paymentDetails
        }

        console.log(data)

        updateSalesHelper(data)
            .then(res => {
                console.log(res)

                props.updateSalesAlertAction(true)
                setTimeout(() => {
                    props.updateSalesAlertAction(false)
                }, 5000)

                getAllSalesData()
                resetDrawerData()
                handleDrawerToggle()
            })
            .catch(errMsg => {
                setServerError(errMsg)
            })
    }

    const handleCancelSales = id => {
        console.log('in handleCancelSales flow')
    }

    const resetDrawerData = () => {
        setCustomerDetails({})
        setSalesData({})
        setProductDetails({})
        setProductDetailsArray([])
        setAddSalesStage(undefined)
        setPaymentDetails({})
        setOnlineSalesStage(ONLINE_SALES_STAGES.PRODUCT_DETAILS)
        setSalesFlow(SALES_FLOW.OFFLINE)
        setAddProductActive(false)
    }

    const handleSalesCardClickFlow = (salesData, status) => {
        if (
            status === SALES_TYPE.HOLD ||
            (!SALES_TYPE.NON_HOLD_STATUS_LIST.includes(status) &&
                !SALES_TYPE.CONFIRMED_LIST.includes(status))
        ) {
            setHoldOrderDatainState(salesData)
            if (salesData.type === SALES_FLOW.OFFLINE) {
                setSalesFlow(SALES_FLOW.OFFLINE_HOLD)
                handleDrawerToggle()
            } else if (salesData.type === SALES_FLOW.ONLINE) {
                console.log('opening online sales drawer')
                setSalesFlow(SALES_FLOW.ONLINE)
                if (salesData.status === 'CREATED') {
                    handleOnlineDrawerToggle()
                } else {
                    handleOnlinePrescriptionDrawerToggle()
                }
            }
        } else setViewSalesActive(true)
    }

    const getSaleDetails = (id, status) => {
        getSalesOrderHelper(id)
            .then(res => {
                console.log('res so: ', res)
                setSalesData(res)

                handleSalesCardClickFlow(res, status)
                // TODO: prescription logic
            })
            .catch(err => {
                console.log('err so: ', err)
            })

        return null
    }

    const setHoldOrderDatainState = sData => {
        let cDetails = {
            id: sData.customer?.id,
            [CUSTOMER_FIELDS.NAME]: sData.customer?.name,
            [CUSTOMER_FIELDS.PHONE_NUMBER]: sData.customer?.mobile_no,
            [CUSTOMER_FIELDS.BILLING_ADDRESS]: sData.customer?.shipping_address,
            [CUSTOMER_FIELDS.SHIPPING_ADDRESS]: sData.customer?.billing_address
        }

        sData?.product_details?.map(product => {
            product[PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS] =
                product?.inventory || undefined
            product.inventory = []
        })

        let pdArray = sData.product_details

        setCustomerDetails(cDetails)
        setProductDetailsArray(pdArray)
    }

    const handleAddSalesClick = () => {
        if (salesFlow === SALES_FLOW.OFFLINE_HOLD) {
            resetDrawerData()
        }

        handleDrawerToggle()
    }

    const getSalesAmount = () => {
        let pdArray = productDetailsArray

        let amount = 0

        pdArray.map(productDetail => {
            amount +=
                parseFloat(
                    productDetail?.[
                        PRODUCT_DETAIL_FIELDS.SELECTED_BATCH_DETAILS
                    ]?.[PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT]
                ).toFixed(2) *
                parseFloat(
                    productDetail[PRODUCT_DETAIL_FIELDS.QUANTITY]
                ).toFixed(2)
        })

        // paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT]

        return amount
    }

    const getDiscountedAmount = () => {
        let initialAmount = paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] || 0

        let discountPercentage =
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT] || 0

        return parseFloat((discountPercentage / 100) * initialAmount).toFixed(2)
    }

    const getTradeDiscountedAmount = () => {
        let initialAmount = paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] || 0

        let tradeDiscountPercentage =
            paymentDetails?.[
                PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE
            ] || 0

        return parseFloat(
            (tradeDiscountPercentage / 100) * initialAmount
        ).toFixed(2)
    }

    const getVatAmount = () => {
        let amount = paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT] || 0
        let discount =
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT] || 0 || 0
        let tradeDiscount = getTradeDiscountedAmount() || 0

        let remainingAmount = amount - discount - tradeDiscount

        let vatPercentage = paymentDetails?.[PAYMENT_DETAILS_FIELDS.VAT] || 0

        return parseFloat((vatPercentage / 100) * remainingAmount).toFixed(2)
    }

    const getTotalAmount = () => {
        let initialAmount = paymentDetails?.[PAYMENT_DETAILS_FIELDS.AMOUNT]
        let discountedAmount = parseFloat(
            paymentDetails?.[PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT] || 0
        )
        let tradeDiscountedAmount = parseFloat(getTradeDiscountedAmount())
        let vat = parseFloat(getVatAmount())

        let remainingAmount =
            initialAmount - discountedAmount - tradeDiscountedAmount + vat || 0

        console.log(
            'iniAmo, discountedAmount, tradeDiscountedAmount, vat, remainingAmount',
            initialAmount,
            discountedAmount,
            tradeDiscountedAmount,
            vat,
            remainingAmount
        )
        return Number(parseFloat(remainingAmount).toFixed(2))
    }

    const getAlertText = () => {
        if (createSalesAlert) return 'Sales Order created Successfully!'
        else if (holdSalesAlert) return 'Sales Order put on Hold Successfully!'
        else if (updateSalesAlert) return 'Sales Order updated Successfully!'
        else if (onlineSalesConfirmationAlert)
            return 'Online Sales Order Confirmed Successfully'
        else if (onlineSalesCancellationAlert)
            return 'Online Sales Order Cancelled Successfully'
    }

    const getConfirmedOrderStatus = sale => {
        let text = ''
        let color = ''
        if (
            SALES_TYPE.CONFIRMED_LIST.includes(sale.status) &&
            sale.type === SALES_FLOW.ONLINE
        ) {
            if (sale.status === SALES_TYPE.COMPLETED) {
                text = 'Order Delivered'
                color = 'textGreen'
            } else if (sale?.delivery_partner?.id) {
                text = 'Delivery person assigned'
                color = 'primaryText'
            }
        }
        return {
            text,
            color
        }
    }

    return (
        <>
            {isMobile && <MobileHeader headerOptions={headerOptions} />}
            {(createSalesAlert ||
                holdSalesAlert ||
                updateSalesAlert ||
                onlineSalesConfirmationAlert ||
                onlineSalesCancellationAlert) && (
                <AcknowledgeScreen
                    icon={<CheckPrimaryIcon />}
                    main={getAlertText()}
                    sub="Lorem ipsum dolor sit amet consectetur. Ligula ut volutpat morbi ullamcorper"
                    success
                    alertType="success"
                    alertMessage={getAlertText()}
                />
            )}
            <div className="pageBodyRightPanel">
                {isMobile && (
                    <div className="addProductBtnMobile dFlex aiCenter mwebVisible fixed">
                        <div
                            className={'formGroup mb-0 searchBar expand'}
                            onClick={expandSearch}
                            onBlur={() => setViewSearch(false)}>
                            <input
                                ref={inputRef}
                                type="text"
                                className="formControl"
                                placeholder="Search By Customer Number"
                                onChange={e => setSearchTerm(e?.target?.value)}
                            />
                            <div className={`searchIcon`}>
                                <SearchIcon />
                            </div>
                        </div>
                        <button
                            className="animated zoomIn btn btnPrimary ml-2"
                            onClick={handleAddSalesClick}>
                            <AddIcon />
                        </button>
                    </div>
                )}
                {!isMobile && (
                    <div className="pageBodyRightPanelHeader dFlex jcSpaceBetween aiCenter mb-5">
                        <div className="pageBodyRightPanelHeading">Sales</div>
                        <div className="addProductBtn dFlex aiCenter">
                            <div
                                className={`formGroup mb-0 searchBar expand`}
                                onClick={expandSearch}
                                onBlur={() => setViewSearch(false)}>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    className="formControl"
                                    placeholder="Search By Customer Number"
                                    onChange={e =>
                                        setSearchTerm(e?.target?.value)
                                    }
                                />
                                <div className={`searchIcon`}>
                                    <SearchIcon />
                                </div>
                            </div>
                            {
                                <button
                                    className="btn btnPrimary ml-4"
                                    onClick={handleAddSalesClick}>
                                    ADD
                                </button>
                            }
                            <div
                                className="pointer ml-4"
                                onClick={() => props.onClose()}>
                                <CloseIcon />
                            </div>
                        </div>
                    </div>
                )}
                <div className="consolidateStrip dFlex jcSpaceBetween aiCenter">
                    <div className="consolidateDataIcon dFlex">
                        <ProductReceivingBlack />
                    </div>
                    <div className="consolidateDataText">
                        Sales ({stats?.month_name || '-'}) <small>BDT</small>
                    </div>
                    <div className="consolidateData">
                        {stats?.total_amount?.total_amount}
                    </div>
                </div>
                <div className="salesWrapper mt-5">
                    <ul className="tabs dFlex">
                        <li
                            className={`tabItem ${
                                activeSalesTab === SALES_TYPE.COMPLETED
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() =>
                                setActiveSalesTab(SALES_TYPE.COMPLETED)
                            }>
                            Confirmed
                        </li>
                        <li
                            className={`tabItem ${
                                activeSalesTab === SALES_TYPE.HOLD
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() => setActiveSalesTab(SALES_TYPE.HOLD)}>
                            Draft & Hold
                        </li>
                        <li
                            className={`tabItem ${
                                activeSalesTab === SALES_TYPE.CANCELLED
                                    ? 'active'
                                    : ''
                            }`}
                            onClick={() =>
                                setActiveSalesTab(SALES_TYPE.CANCELLED)
                            }>
                            Cancelled
                        </li>
                    </ul>
                    <div class="tabContent">
                        <div className="tabPane dFlex">
                            {allSalesFilteredData?.map(sale => {
                                if (
                                    sale.status === activeSalesTab ||
                                    (activeSalesTab === SALES_TYPE.HOLD &&
                                        !SALES_TYPE.NON_HOLD_STATUS_LIST.includes(
                                            sale.status
                                        )) ||
                                    (activeSalesTab === SALES_TYPE.COMPLETED &&
                                        SALES_TYPE.CONFIRMED.includes(
                                            sale.status
                                        ))
                                ) {
                                    return (
                                        <div className="cardWrap">
                                            <Card
                                                cardCrown={
                                                    SALES_TYPE_BACKEND_MAPPING[
                                                        sale.type
                                                    ]
                                                }
                                                primary={
                                                    sale.type ===
                                                    SALES_FLOW.ONLINE
                                                }
                                                cardRight={
                                                    sale.total_amount
                                                        ? `BDT ${sale.total_amount}`
                                                        : 'BDT 0'
                                                }
                                                headerSup={formatDate(
                                                    sale.created_at
                                                )}
                                                headerMain={sale.invoice_number}
                                                sales={
                                                    sale.type ===
                                                    SALES_FLOW.OFFLINE
                                                        ? `Customer: ${sale.customer_name}`
                                                        : 'Customer: DG ePharma'
                                                }
                                                orderStatus={getConfirmedOrderStatus(
                                                    sale
                                                )}
                                                handleClick={() =>
                                                    getSaleDetails(
                                                        sale.id,
                                                        sale.status
                                                    )
                                                }
                                                // actionButtons
                                            />
                                        </div>
                                    )
                                } else {
                                    return
                                }
                            })}
                        </div>
                        <div className="tabPane dFlex">
                            {/* <div className='cardWrap'>
                                <Card
                                    cardCrown="Online"
                                    online
                                    cardRight="BDT 25,00,000"
                                    headerSup='28 Dec 2023'
                                    headerMain='FFDR3456789'
                                    sales="Ahanf Sabbir"
                                    actionButtons
                                    handleClick={() => setOnlineDrawerOpen(true)} />
                            </div>
                            <div className='cardWrap'>
                                <Card
                                    cardCrown="In Store"
                                    cardRight="BDT 25,00,000"
                                    headerSup='28 Dec 2023'
                                    headerMain='FFDR3456789'
                                    sales="Ahanf Sabbir"
                                    actionButtons />
                            </div> */}
                        </div>
                    </div>
                </div>
                <ViewSales
                    open={isViewSalesActive}
                    handleDrawerToggle={() =>
                        setViewSalesActive(!isViewSalesActive)
                    }
                    data={salesData}
                />
                {/** offline sales order sideDrawer */}
                {offlineDrawerOpen && (
                    <SideDrawer
                        handleDrawerToggle={handleDrawerToggle}
                        open={offlineDrawerOpen}
                        drawerButton={
                            permissions?.features?.[
                                PERMISSION_FEATURE.SALES
                            ] === PERMISSION_TYPE.MANAGE ||
                            !(
                                addSalesStage ===
                                    ADD_SALES_STAGES.PRODUCT_DETAILS &&
                                !addProductActive
                            )
                        }
                        holdButton={
                            addSalesStage ===
                                ADD_SALES_STAGES.PRODUCT_DETAILS &&
                            !addProductActive &&
                            productDetailsArray?.length > 0 &&
                            salesFlow !== SALES_FLOW.OFFLINE_HOLD // TODO: add a check of online hold also
                        }
                        handleHoldClick={() => handleHoldSales()}
                        drawerHeading="Add New Sales"
                        buttonText={
                            addProductActive &&
                            addSalesStage === ADD_SALES_STAGES.PRODUCT_DETAILS
                                ? 'Save'
                                : 'Next'
                        }
                        handleClick={
                            addProductActive &&
                            addSalesStage === ADD_SALES_STAGES.PRODUCT_DETAILS
                                ? () => handleAddProductClick()
                                : () => handleNextClick()
                        }>
                        <WizardSteps
                            wizardState={wizardState}
                            onStepClick={step =>
                                onAddSalesWizardStepClick(step)
                            }
                        />
                        <div className="wizardFieldset">
                            {serverError && (
                                <div class="invalidFeedback mb-4">
                                    {serverError}
                                </div>
                            )}
                            {addSalesStage ===
                                ADD_SALES_STAGES.CUSTOMER_DETAILS && (
                                <form
                                    onSubmit={e => e.preventDefault()}
                                    className="mt-5">
                                    <div className="formHeading">
                                        Customer Details
                                    </div>
                                    <div className="formInline dFlex flexNowrap">
                                        <div className="formGroup formFloating fgrow1 maxWidth">
                                            <select
                                                className="formSelect"
                                                id="floatingSelect">
                                                {/* <option value="1">+91</option> */}
                                                <option value="2">+88</option>
                                            </select>
                                            <label for="floatingSelect">
                                                Country
                                            </label>
                                            {false && (
                                                <div className="invalidFeedback">
                                                    Please choose country code.
                                                </div>
                                            )}
                                        </div>
                                        <div className="formGroup formFloating ml-4 fgrow1">
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="phone"
                                                placeholder="Phone"
                                                value={
                                                    customerDetails?.[
                                                        CUSTOMER_FIELDS
                                                            .PHONE_NUMBER
                                                    ] || ''
                                                }
                                                onChange={e => {
                                                    updateCustomerDetails(
                                                        CUSTOMER_FIELDS.PHONE_NUMBER,
                                                        e.target.value
                                                    )
                                                }}
                                                onBlur={e => getCustomer()}
                                                required
                                            />
                                            <label for="phone">Phone</label>
                                            {isFieldErrorVisible &&
                                                !customerDetails?.[
                                                    CUSTOMER_FIELDS.PHONE_NUMBER
                                                ] && (
                                                    <div className="invalidFeedback">
                                                        Please select a valid
                                                        Phone.
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className="formGroup formFloating">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="name"
                                            placeholder="Name"
                                            value={
                                                customerDetails?.[
                                                    CUSTOMER_FIELDS.NAME
                                                ] || ''
                                            }
                                            onChange={e =>
                                                updateCustomerDetails(
                                                    CUSTOMER_FIELDS.NAME,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="address">Name</label>
                                        {isFieldErrorVisible &&
                                            !customerDetails?.[
                                                CUSTOMER_FIELDS.NAME
                                            ] && (
                                                <div className="invalidFeedback">
                                                    Please enter a valid Name.
                                                </div>
                                            )}
                                    </div>
                                    <div className="formGroup formFloating">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="billingAddress"
                                            placeholder="Address"
                                            value={
                                                customerDetails?.[
                                                    CUSTOMER_FIELDS
                                                        .BILLING_ADDRESS
                                                ] || ''
                                            }
                                            onChange={e =>
                                                updateCustomerDetails(
                                                    CUSTOMER_FIELDS.BILLING_ADDRESS,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="address">Address</label>
                                        {isFieldErrorVisible &&
                                            !customerDetails?.[
                                                CUSTOMER_FIELDS.BILLING_ADDRESS
                                            ] && (
                                                <div className="invalidFeedback">
                                                    Please enter a valid
                                                    Address.
                                                </div>
                                            )}
                                    </div>
                                    {/* <div className="formGroup formFloating">
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="shippingAddress"
                                            placeholder="Shipping Address"
                                            value={
                                                customerDetails?.[
                                                    CUSTOMER_FIELDS
                                                        .SHIPPING_ADDRESS
                                                ] || ''
                                            }
                                            onChange={e =>
                                                updateCustomerDetails(
                                                    CUSTOMER_FIELDS.SHIPPING_ADDRESS,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="address">
                                            Shipping Address
                                        </label>
                                        {isFieldErrorVisible &&
                                            !customerDetails?.[
                                                CUSTOMER_FIELDS.SHIPPING_ADDRESS
                                            ] && (
                                                <div className="invalidFeedback">
                                                    Please enter a valid
                                                    Shipping Address.
                                                </div>
                                            )}
                                    </div> */}
                                </form>
                            )}
                            {addSalesStage ===
                                ADD_SALES_STAGES.PRODUCT_DETAILS && (
                                <>
                                    <div className="addedProductsListWrap">
                                        <ul className="addedProductsList">
                                            {productDetailsArray?.length > 0 &&
                                                productDetailsArray.map(
                                                    product => {
                                                        return (
                                                            <li className="addedProduct">
                                                                <div className="dFlex jcSpaceBetween aiCenter">
                                                                    <div className="addedProductName">
                                                                        {
                                                                            product?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .PRODUCT_NAME
                                                                            ]
                                                                        }
                                                                    </div>
                                                                    <div className="addedProductAction dFlex">
                                                                        <div
                                                                            className="trashIcon"
                                                                            onClick={() =>
                                                                                setDeleteProductPopup(
                                                                                    true
                                                                                )
                                                                            }>
                                                                            <TrashIcon />
                                                                        </div>

                                                                        <div
                                                                            className={`toggleArrow ${
                                                                                product.product_id ===
                                                                                addedProductVisibleId
                                                                                    ? 'show'
                                                                                    : 'hide'
                                                                            }`}
                                                                            onClick={() => {
                                                                                if (
                                                                                    product.product_id ===
                                                                                    addedProductVisibleId
                                                                                )
                                                                                    setAddedProductDetailsId(
                                                                                        undefined
                                                                                    )
                                                                                else
                                                                                    setAddedProductDetailsId(
                                                                                        product.product_id
                                                                                    )
                                                                            }}>
                                                                            <ArrowDown />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {deleteProductPopup && (
                                                                    <PopupCard
                                                                        header="Delete Product 1?"
                                                                        body="Lorem ipsum dolor sit t consectetur. Nulla bibendum ac ornare sodales.?">
                                                                        <button
                                                                            className="btn btnPrimaryOutlined dFlex"
                                                                            onClick={() =>
                                                                                setDeleteProductPopup(
                                                                                    false
                                                                                )
                                                                            }>
                                                                            Cancel
                                                                        </button>
                                                                        <button
                                                                            className="btn btnPrimary dFlex"
                                                                            onClick={() =>
                                                                                handleRemoveProductClick(
                                                                                    product
                                                                                )
                                                                            }>
                                                                            Delete
                                                                        </button>
                                                                    </PopupCard>
                                                                )}
                                                                {addedProductVisibleId ===
                                                                    product.product_id && (
                                                                    <Product
                                                                        isFieldErrorVisible={
                                                                            isFieldErrorVisible
                                                                        }
                                                                        handleProductUpdate={(
                                                                            id,
                                                                            key,
                                                                            value
                                                                        ) => {
                                                                            setServerError(
                                                                                undefined
                                                                            )
                                                                            handleExistingProductUpdate(
                                                                                id,
                                                                                key,
                                                                                value
                                                                            )
                                                                        }}
                                                                        product={
                                                                            product
                                                                        }
                                                                        isBarcodeDisabled={
                                                                            true
                                                                        }
                                                                    />
                                                                )}
                                                            </li>
                                                        )
                                                    }
                                                )}
                                            {addProductActive && (
                                                <li className="addedProduct">
                                                    <Product
                                                        isFieldErrorVisible={
                                                            isFieldErrorVisible
                                                        }
                                                        handleProductUpdate={(
                                                            id,
                                                            key,
                                                            value
                                                        ) => {
                                                            setServerError(
                                                                undefined
                                                            )

                                                            handleProductDetailsUpdate(
                                                                id,
                                                                key,
                                                                value
                                                            )
                                                        }}
                                                        clearProduct={() => {
                                                            setProductDetails(
                                                                undefined
                                                            )
                                                            setAddProductActive(
                                                                false
                                                            )
                                                        }}
                                                        getProductDetailsByBarcode={barCode =>
                                                            getProductDetailsByBarcode(
                                                                barCode
                                                            )
                                                        }
                                                        updateProductDetails={pDetails =>
                                                            setProductDetails(
                                                                pDetails
                                                            )
                                                        }
                                                        product={productDetails}
                                                    />
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                    {!addProductActive && (
                                        <div
                                            className="addItemButton"
                                            onClick={() =>
                                                setAddProductActive(true)
                                            }>
                                            <button className="btn btnPrimary dFlex">
                                                <AddIcon />
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                            {addSalesStage ===
                                ADD_SALES_STAGES.PAYMENT_DETAILS && (
                                <form>
                                    <div className="formGroup formFloating">
                                        <textarea
                                            rows="2"
                                            type="text"
                                            className="formControl"
                                            id="notes"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS.NOTES
                                                ]
                                            }
                                            onChange={e =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.NOTES,
                                                    e.target.value
                                                )
                                            }
                                            required></textarea>
                                        <label for="notes">Notes</label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please enter notes.
                                            </div>
                                        )}
                                    </div>
                                    {/* <div className="formGroup formFloating fgrow1 maxWidth">
                                    <select
                                        className="formSelect"
                                        id="floatingSelect"
                                    >
                                        <option value="none" selected disabled hidden>Select an Option</option>
                                        <option value="NetMeds">NetMeds</option>
                                        <option value="DavaIndia">DavaIndia</option>
                                    </select>
                                    <label for="floatingSelect">Item received By</label>
                                    {false && <div class="invalidFeedback">
                                        Please choose Item received By.
                                    </div>}
                                </div> */}
                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                BDT
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="email"
                                            placeholder="Amount(BDT)"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS
                                                        .AMOUNT
                                                ]
                                            }
                                            readOnly={true}
                                            onChange={e =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.AMOUNT,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="address">Amount(BDT)</label>
                                        {false && (
                                            <div className="invalidFeedback">
                                                Please enter Amount(BDT).
                                            </div>
                                        )}
                                    </div>
                                    <div className="formInline dFlex flexNowrap">
                                        <div className="formGroup formFloating formInputText">
                                            <div className="preInputGroup backColor">
                                                <div className="preInputText">
                                                    %
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="amount"
                                                placeholder="Discount"
                                                value={
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .DISCOUNT
                                                    ]
                                                }
                                                onChange={e =>
                                                    handlePaymentDetailsUpdate(
                                                        PAYMENT_DETAILS_FIELDS.DISCOUNT,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="amount">
                                                Discount(%)
                                            </label>
                                            {false && (
                                                <div class="invalidFeedback">
                                                    Please enter a valid
                                                    Discount.
                                                </div>
                                            )}
                                        </div>

                                        <div className="formGroup formFloating formInputText fgrow1 ml-4">
                                            <div className="preInputGroup backColor">
                                                <div className="preInputText">
                                                    BDT
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="amount"
                                                placeholder="Discount"
                                                value={
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .DISCOUNT_AMOUNT
                                                    ]
                                                }
                                                onChange={e =>
                                                    handlePaymentDetailsUpdate(
                                                        PAYMENT_DETAILS_FIELDS.DISCOUNT_AMOUNT,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="amount">
                                                Discount(BDT)
                                            </label>
                                            {false && (
                                                <div class="invalidFeedback">
                                                    Please enter a valid
                                                    Discount.
                                                </div>
                                            )}
                                        </div>

                                        {/* <div className="amountPercentage">
                                            <div className="currencyType">
                                                BDT
                                            </div>
                                            <div className="currencyValue">
                                                {getDiscountedAmount()}
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div className="formInline dFlex flexNowrap">
                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                %
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="amount"
                                            placeholder="Trade Discount"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS
                                                        .TRADE_DISCOUNT_PERCENTAGE
                                                ]
                                            }
                                            onChange={e =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.TRADE_DISCOUNT_PERCENTAGE,
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                        <label for="amount">
                                            Trade Discount(%)
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Trade
                                                Discount.
                                            </div>
                                        )}
                                    </div>
                                    <div className="amountPercentage">
                                        <div className="currencyType">BDT</div>
                                        <div className="currencyValue">
                                            {getTradeDiscountedAmount()}
                                        </div>
                                    </div>
                                </div> */}
                                    <div className="formInline dFlex flexNowrap">
                                        <div className="formGroup formFloating formInputText">
                                            <div className="preInputGroup backColor">
                                                <div className="preInputText">
                                                    %
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="amount"
                                                placeholder="VAT"
                                                value={
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .VAT
                                                    ]
                                                }
                                                onChange={e =>
                                                    handlePaymentDetailsUpdate(
                                                        PAYMENT_DETAILS_FIELDS.VAT,
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            />
                                            <label for="amount">VAT(%)</label>
                                            {false && (
                                                <div class="invalidFeedback">
                                                    Please enter a valid VAT.
                                                </div>
                                            )}
                                        </div>
                                        <div className="amountPercentage">
                                            <div className="currencyType">
                                                BDT
                                            </div>
                                            <div className="currencyValue">
                                                {getVatAmount()}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup formFloating formInputText">
                                        <div className="preInputGroup backColor">
                                            <div className="preInputText">
                                                BDT
                                            </div>
                                        </div>
                                        <input
                                            type="text"
                                            className="formControl"
                                            id="totalamount"
                                            placeholder="Total Amount"
                                            value={
                                                paymentDetails?.[
                                                    PAYMENT_DETAILS_FIELDS
                                                        .TOTAL_AMOUNT
                                                ]
                                            }
                                            readOnly={true}
                                            // onChange={(e) => handlePaymentDetailsUpdate(PAYMENT_DETAILS_FIELDS.TOTAL_AMOUNT, e.target.value)}
                                            required
                                        />
                                        <label for="totalamount">
                                            Total Amount(BDT)
                                        </label>
                                        {false && (
                                            <div class="invalidFeedback">
                                                Please enter a valid Total
                                                Amount.
                                            </div>
                                        )}
                                    </div>
                                    <div className="formGroup formFloating fgrow1 maxWidth">
                                        <Select
                                            className="formSelect reactSelect"
                                            id="floatingSelect"
                                            value={{
                                                label: convertToTitleCase(
                                                    paymentDetails?.[
                                                        PAYMENT_DETAILS_FIELDS
                                                            .PAYMENT_MODE
                                                    ]
                                                )
                                            }}
                                            onChange={option =>
                                                handlePaymentDetailsUpdate(
                                                    PAYMENT_DETAILS_FIELDS.PAYMENT_MODE,
                                                    option.name
                                                )
                                            }
                                            options={PAYMENT_MODE_OPTIONS}
                                            getOptionLabel={option =>
                                                option?.label
                                            }
                                            placeholder="Payment Mode"
                                            styles={{
                                                control: base => ({
                                                    border: 0,
                                                    display: 'flex',
                                                    paddingTop: '20px'
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor:
                                                        state.isFocused
                                                            ? 'rgb(222, 235, 255)'
                                                            : state.isSelected
                                                            ? '#FFF'
                                                            : 'inherit',
                                                    color: '#212529',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            state.isSelected
                                                                ? '#DEEBFF'
                                                                : 'rgb(222, 235, 255)'
                                                    }
                                                })
                                            }}
                                            // menuIsOpen={true}
                                            isClearable
                                        />
                                        <label for="floatingSelect">
                                            Payment Mode
                                        </label>
                                        {isFieldErrorVisible &&
                                            !paymentDetails?.[
                                                PAYMENT_DETAILS_FIELDS
                                                    .PAYMENT_MODE
                                            ] && (
                                                <div class="invalidFeedback">
                                                    Please choose Payment Mode.
                                                </div>
                                            )}
                                    </div>
                                    {paymentDetails?.[
                                        PAYMENT_DETAILS_FIELDS.PAYMENT_MODE
                                    ] === 'CASH_CARD' && (
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating formInputText fgrow1">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        BDT
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="amountCash"
                                                    placeholder="Amount in Cash"
                                                    value={
                                                        paymentDetails?.[
                                                            PAYMENT_DETAILS_FIELDS
                                                                .AMOUNT_CASH
                                                        ]
                                                    }
                                                    onChange={e =>
                                                        handlePaymentDetailsUpdate(
                                                            PAYMENT_DETAILS_FIELDS.AMOUNT_CASH,
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                                <label for="amountCash">
                                                    Amount in Cash
                                                </label>
                                                {false && (
                                                    <div class="invalidFeedback">
                                                        Please enter a valid
                                                        Cash amount.
                                                    </div>
                                                )}
                                            </div>
                                            <div className="formGroup formFloating formInputText fgrow1 ml-4">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        BDT
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="amountCard"
                                                    placeholder="Amount in Card"
                                                    value={
                                                        paymentDetails?.[
                                                            PAYMENT_DETAILS_FIELDS
                                                                .AMOUNT_CARD
                                                        ]
                                                    }
                                                    onChange={e =>
                                                        handlePaymentDetailsUpdate(
                                                            PAYMENT_DETAILS_FIELDS.AMOUNT_CARD,
                                                            e.target.value
                                                        )
                                                    }
                                                    required
                                                />
                                                <label for="amountCard">
                                                    Amount in Card
                                                </label>
                                                {false && (
                                                    <div class="invalidFeedback">
                                                        Please enter a valid
                                                        Card Amount.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </div>
                    </SideDrawer>
                )}
                {/** online sales order sideDrawer */}
                <SideDrawer
                    handleDrawerToggle={() => handleOnlineDrawerToggle()}
                    open={onlineDrawerOpen}
                    drawerButton={
                        permissions?.features?.[PERMISSION_FEATURE.SALES] ===
                        PERMISSION_TYPE.MANAGE
                    }
                    buttonText="CONFIRM"
                    holdButton={
                        permissions?.features?.[PERMISSION_FEATURE.SALES] ===
                        PERMISSION_TYPE.MANAGE
                    }
                    holdButtonText="CANCEL"
                    drawerHeading="Add New Sales"
                    handleClick={() => {
                        handleOnlineOrderNextClick()
                    }}
                    handleHoldClick={() => {
                        confirmOnlineSalesOrder(SALES_TYPE.CANCELLED)
                    }}>
                    <WizardSteps
                        wizardState={wizardState}
                        onStepClick={step => onOnlineSalesWizardStepClick(step)}
                    />
                    <div className="wizardFieldset">
                        {serverError && (
                            <div class="invalidFeedback mb-4">
                                {serverError}
                            </div>
                        )}
                        {onlineSalesStage ===
                            ONLINE_SALES_STAGES.PRODUCT_DETAILS && (
                            <div className="salesOnline">
                                <div className="viewProductWrap">
                                    <div className="viewProductHeader">
                                        <div className="headerRow dFlex jcSpaceBetween aiCenter">
                                            <div className="ProductUniqueTag">
                                                {salesData?.invoice_number}
                                            </div>
                                            <div className="headerRowRight dFlex flexColumn">
                                                {formatDate(
                                                    salesData?.created_at
                                                )}{' '}
                                                <small>
                                                    {formatTime(
                                                        salesData?.created_at
                                                    )}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="viewProductHeader">
                                        <div className="contactDetailsRow dFlex jcSpaceBetween">
                                            <div className="productSupplierName">
                                                DG ePharma
                                            </div>

                                            <div className="productSupplierName">
                                                {salesData.type !==
                                                'IN_STORE' ? (
                                                    <a
                                                        href={`tel:+${'09606501155'}`}>
                                                        <CallIcon />
                                                    </a>
                                                ) : (
                                                    salesData?.customer
                                                        ?.mobile_no
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prescriptionImages">
                                        <div className="prescriptionImagesWrap dFlex">
                                            {salesData?.files?.map(file => {
                                                return (
                                                    <div className="prescriptionImage">
                                                        <a
                                                            download={
                                                                file?.name
                                                            }
                                                            target="_blank"
                                                            href={file.url}
                                                            title={file?.name}>
                                                            <img
                                                                src={file?.url}
                                                                alt={file?.name}
                                                            />
                                                        </a>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <table className="table onlineSales">
                                        <thead>
                                            <tr>
                                                <td
                                                    width={`${
                                                        isMobile ? '50%' : '60%'
                                                    }`}>
                                                    Medicine
                                                </td>
                                                <td
                                                    width={`${
                                                        isMobile ? '70%' : '20%'
                                                    }`}>
                                                    Qty
                                                </td>
                                                <td
                                                    className="strong"
                                                    width={`${
                                                        isMobile ? '70%' : '20%'
                                                    }`}>
                                                    Amount
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {productDetailsArray?.map(
                                                product => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        {`${
                                                                            product?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .PRODUCT_NAME
                                                                            ]
                                                                        } ${
                                                                            product?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .DOSE
                                                                            ]
                                                                        }
                                                                `}
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        {
                                                                            product
                                                                                ?.inventory
                                                                                ?.barcode
                                                                        }
                                                                        {`(Batch - ${
                                                                            product?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .SELECTED_BATCH_DETAILS
                                                                            ]?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .BATCH_NUMBER
                                                                            ]
                                                                        }, Expiry - ${formatDate(
                                                                            product?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .SELECTED_BATCH_DETAILS
                                                                            ]?.[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .EXPIRY_DATE
                                                                            ]
                                                                        )})
                                                                `}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {/* <div className="formGroup"> */}
                                                                    <span>
                                                                        {
                                                                            product[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .QUANTITY
                                                                            ]
                                                                        }
                                                                    </span>
                                                                    {/* </div> */}
                                                                </td>
                                                                <td>
                                                                    {/* <div className="formGroup"> */}
                                                                    <span className="strong">
                                                                        BDT{' '}
                                                                        {product[
                                                                            PRODUCT_DETAIL_FIELDS
                                                                                .FINAL_PRICE
                                                                        ] *
                                                                            product[
                                                                                PRODUCT_DETAIL_FIELDS
                                                                                    .QUANTITY
                                                                            ]}
                                                                    </span>
                                                                    {/* </div> */}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                        {onlineSalesStage ===
                            ONLINE_SALES_STAGES.PAYMENT_DETAILS && (
                            <div className="salesOnline">
                                <div className="viewProductWrap">
                                    <div className="viewProductHeader">
                                        <div className="headerRow dFlex jcSpaceBetween aiCenter">
                                            <div className="ProductUniqueTag">
                                                {salesData?.invoice_number}
                                            </div>
                                            <div className="headerRowRight dFlex flexColumn">
                                                {formatDate(
                                                    salesData?.created_at
                                                )}{' '}
                                                <small>
                                                    {formatTime(
                                                        salesData?.created_at
                                                    )}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="viewProductHeader">
                                        <div className="contactDetailsRow dFlex">
                                            <div className="productSupplierName">
                                                DG ePharma{' '}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="viewProductHeader">
                                        <table
                                            className="table productPrice pt-4"
                                            cellPadding={2}
                                            cellSpacing={2}>
                                            <tbody>
                                                <tr>
                                                    <td>Amount (BDT)</td>
                                                    <td align="right">
                                                        {salesData?.amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>VAT</td>
                                                    <td align="right">
                                                        {salesData?.vat}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Discount </td>
                                                    <td align="right">
                                                        {salesData?.discount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="strong">
                                                        Total (BDT)
                                                    </td>
                                                    <td
                                                        className="strong"
                                                        align="right">
                                                        {
                                                            salesData?.total_amount
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="disclaimerNotes">
                                        <div className="disclaimerNotesHeading">
                                            Payment Method
                                        </div>
                                        <div className="disclaimerNotesDesc">
                                            Cash on Delivery
                                        </div>
                                    </div>
                                    {/* Delivery Partner selection */}
                                    {/* <div className="viewProductHeader">
                                        <div className="formGroup formFloating">
                                            <ReactSelect
                                                className="formSelect reactSelect"
                                                id="floatingSelect"
                                                value={selectedDeliveryPartner}
                                                options={
                                                    deliveryPartnerOptionList
                                                }
                                                onChange={option =>
                                                    setSelectedDeliveryPartner(
                                                        option
                                                    )
                                                }
                                                getOptionLabel={option =>
                                                    option.name
                                                }
                                                placeholder="Select Delivery Partner"
                                                styles={{
                                                    control: base => ({
                                                        border: 0,
                                                        display: 'flex',
                                                        paddingTop: '20px'
                                                    }),
                                                    option: (
                                                        provided,
                                                        state
                                                    ) => ({
                                                        ...provided,
                                                        backgroundColor:
                                                            state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                        color: '#212529',
                                                        '&:hover': {
                                                            backgroundColor:
                                                                state.isSelected
                                                                    ? '#DEEBFF'
                                                                    : 'rgb(222, 235, 255)'
                                                        }
                                                    })
                                                }}
                                                isClearable
                                            />
                                            <label for="floatingSelect">
                                                Delivery Partner
                                            </label>
                                            {isFieldErrorVisible &&
                                                !selectedDeliveryPartner && (
                                                    <div className="invalidFeedback">
                                                        Please choose Delivery
                                                        Partner.
                                                    </div>
                                                )}
                                        </div>
                                    </div> */}
                                </div>

                                {false && (
                                    <div className="dFlex aiCenter flexColumn">
                                        <PendingImage />
                                        <div className="">
                                            Add medications as per prescription
                                            to the cart
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </SideDrawer>
                {/*** online sales with prescription  */}
                {onlinePrescriptionDrawerOpen && (
                    <SideDrawer
                        handleDrawerToggle={() =>
                            handleOnlinePrescriptionDrawerToggle()
                        }
                        open={onlinePrescriptionDrawerOpen}
                        drawerButton={
                            permissions?.features?.[
                                PERMISSION_FEATURE.SALES
                            ] === PERMISSION_TYPE.MANAGE
                        }
                        buttonText={
                            addProductActive &&
                            onlineSalesStage ===
                                ADD_SALES_STAGES.PRODUCT_DETAILS
                                ? 'Save'
                                : 'Confirm'
                        }
                        holdButton={
                            onlineSalesStage ===
                                ONLINE_SALES_STAGES.PAYMENT_DETAILS &&
                            permissions?.features?.[
                                PERMISSION_FEATURE.SALES
                            ] === PERMISSION_TYPE.MANAGE
                        }
                        holdButtonText="CANCEL"
                        drawerHeading="Add New Sales"
                        handleClick={
                            addProductActive &&
                            onlineSalesStage ===
                                ADD_SALES_STAGES.PRODUCT_DETAILS
                                ? () => handleAddProductClick()
                                : () =>
                                      handleOnlineOrderWithPrescriptionNextClick()
                        }
                        handleHoldClick={() => {
                            confirmOnlineSalesOrder(SALES_TYPE.CANCELLED)
                        }}>
                        <WizardSteps
                            wizardState={wizardState}
                            onStepClick={step =>
                                onOnlineSalesWizardStepClick(step)
                            }
                        />
                        <div className="wizardFieldset">
                            {serverError && (
                                <div class="invalidFeedback mb-4">
                                    {serverError}
                                </div>
                            )}
                            {onlineSalesStage ===
                                ONLINE_SALES_STAGES.PRODUCT_DETAILS && (
                                <>
                                    <div className="viewProductWrap">
                                        <div className="viewProductHeader">
                                            <div className="headerRow dFlex jcSpaceBetween aiCenter">
                                                <div className="ProductUniqueTag">
                                                    {salesData?.invoice_number}
                                                </div>
                                                <div className="headerRowRight dFlex flexColumn">
                                                    {formatDate(
                                                        salesData?.created_at
                                                    )}{' '}
                                                    <small>
                                                        {formatTime(
                                                            salesData?.created_at
                                                        )}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="viewProductHeader">
                                            <div className="contactDetailsRow dFlex jcSpaceBetween">
                                                <div className="productSupplierName">
                                                    DG ePharma
                                                </div>

                                                <div className="productSupplierName">
                                                    {salesData.type !==
                                                    'IN_STORE' ? (
                                                        <a
                                                            href={`tel:+${'09606501155'}`}>
                                                            <CallIcon />
                                                        </a>
                                                    ) : (
                                                        salesData?.customer
                                                            ?.mobile_no
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prescriptionImages">
                                            <div className="prescriptionImagesWrap dFlex">
                                                {salesData?.files?.map(file => {
                                                    return (
                                                        <div className="prescriptionImage">
                                                            <a
                                                                download={
                                                                    file?.name
                                                                }
                                                                href={file.url}
                                                                target="_blank"
                                                                title={
                                                                    file?.name
                                                                }>
                                                                <img
                                                                    src={
                                                                        file?.url
                                                                    }
                                                                    alt={
                                                                        file?.name
                                                                    }
                                                                />
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="addedProductsListWrap">
                                            {productDetailsArray?.length < 1 &&
                                                !addProductActive && (
                                                    <div className="prescriptionImages">
                                                        <div className="dFlex aiCenter flexColumn">
                                                            <PendingImage />
                                                            <div className="">
                                                                Add medications
                                                                as per
                                                                prescription to
                                                                the cart
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            <ul className="addedProductsList">
                                                {productDetailsArray?.length >
                                                    0 &&
                                                    productDetailsArray.map(
                                                        product => {
                                                            return (
                                                                <li className="addedProduct">
                                                                    <div className="dFlex jcSpaceBetween aiCenter">
                                                                        <div className="addedProductName">
                                                                            {
                                                                                product?.[
                                                                                    PRODUCT_DETAIL_FIELDS
                                                                                        .PRODUCT_NAME
                                                                                ]
                                                                            }
                                                                        </div>
                                                                        <div className="addedProductAction dFlex">
                                                                            <div
                                                                                className="trashIcon"
                                                                                onClick={() =>
                                                                                    setDeleteProductPopup(
                                                                                        true
                                                                                    )
                                                                                }>
                                                                                <TrashIcon />
                                                                            </div>

                                                                            <div
                                                                                className={`toggleArrow ${
                                                                                    product.product_id ===
                                                                                    addedProductVisibleId
                                                                                        ? 'show'
                                                                                        : 'hide'
                                                                                }`}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        product.product_id ===
                                                                                        addedProductVisibleId
                                                                                    )
                                                                                        setAddedProductDetailsId(
                                                                                            undefined
                                                                                        )
                                                                                    else
                                                                                        setAddedProductDetailsId(
                                                                                            product.product_id
                                                                                        )
                                                                                }}>
                                                                                <ArrowDown />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {deleteProductPopup && (
                                                                        <PopupCard
                                                                            header="Delete Product 1?"
                                                                            body="Lorem ipsum dolor sit t consectetur. Nulla bibendum ac ornare sodales.?">
                                                                            <button
                                                                                className="btn btnPrimaryOutlined dFlex"
                                                                                onClick={() =>
                                                                                    setDeleteProductPopup(
                                                                                        false
                                                                                    )
                                                                                }>
                                                                                Cancel
                                                                            </button>
                                                                            <button
                                                                                className="btn btnPrimary dFlex"
                                                                                onClick={() =>
                                                                                    handleRemoveProductClick(
                                                                                        product
                                                                                    )
                                                                                }>
                                                                                Delete
                                                                            </button>
                                                                        </PopupCard>
                                                                    )}
                                                                    {addedProductVisibleId ===
                                                                        product.product_id && (
                                                                        <Product
                                                                            isFieldErrorVisible={
                                                                                isFieldErrorVisible
                                                                            }
                                                                            handleProductUpdate={(
                                                                                id,
                                                                                key,
                                                                                value
                                                                            ) => {
                                                                                setServerError(
                                                                                    undefined
                                                                                )
                                                                                handleExistingProductUpdate(
                                                                                    id,
                                                                                    key,
                                                                                    value
                                                                                )
                                                                            }}
                                                                            product={
                                                                                product
                                                                            }
                                                                            isBarcodeDisabled={
                                                                                true
                                                                            }
                                                                        />
                                                                    )}
                                                                </li>
                                                            )
                                                        }
                                                    )}
                                                {addProductActive && (
                                                    <li className="addedProduct">
                                                        <Product
                                                            isFieldErrorVisible={
                                                                isFieldErrorVisible
                                                            }
                                                            handleProductUpdate={(
                                                                id,
                                                                key,
                                                                value
                                                            ) => {
                                                                setServerError(
                                                                    undefined
                                                                )
                                                                handleProductDetailsUpdate(
                                                                    id,
                                                                    key,
                                                                    value
                                                                )
                                                            }}
                                                            clearProduct={() => {
                                                                setProductDetails(
                                                                    undefined
                                                                )
                                                                setAddProductActive(
                                                                    false
                                                                )
                                                            }}
                                                            getProductDetailsByBarcode={barCode =>
                                                                getProductDetailsByBarcode(
                                                                    barCode
                                                                )
                                                            }
                                                            updateProductDetails={pDetails =>
                                                                setProductDetails(
                                                                    pDetails
                                                                )
                                                            }
                                                            product={
                                                                productDetails
                                                            }
                                                        />
                                                    </li>
                                                )}
                                            </ul>
                                        </div>

                                        {!addProductActive && (
                                            <div
                                                className="addItemButton"
                                                onClick={() =>
                                                    setAddProductActive(true)
                                                }>
                                                <button className="btn btnPrimary dFlex">
                                                    <AddIcon />
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {onlineSalesStage ===
                                ONLINE_SALES_STAGES.PAYMENT_DETAILS && (
                                <div className="salesOnline">
                                    <div className="viewProductWrap">
                                        <div className="viewProductHeader">
                                            <div className="headerRow dFlex jcSpaceBetween aiCenter">
                                                <div className="ProductUniqueTag">
                                                    {salesData?.invoice_number}
                                                </div>
                                                <div className="headerRowRight dFlex flexColumn">
                                                    {formatDate(
                                                        salesData?.created_at
                                                    )}{' '}
                                                    <small>
                                                        {formatTime(
                                                            salesData?.created_at
                                                        )}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="viewProductHeader">
                                            <div className="contactDetailsRow dFlex">
                                                <div className="productSupplierName">
                                                    {
                                                        customerDetails?.[
                                                            CUSTOMER_FIELDS.NAME
                                                        ]
                                                    }
                                                </div>
                                                <div className="productSupplierName">
                                                    {
                                                        customerDetails?.[
                                                            CUSTOMER_FIELDS
                                                                .PHONE_NUMBER
                                                        ]
                                                    }
                                                </div>
                                            </div>
                                            <div className="productSupplierAddress dFlex aiCenter">
                                                <LocationIcon className="icon" />{' '}
                                                {
                                                    customerDetails?.[
                                                        CUSTOMER_FIELDS
                                                            .SHIPPING_ADDRESS
                                                    ]
                                                }{' '}
                                            </div>
                                        </div>
                                        <div className="prescriptionImages">
                                            <div className="prescriptionImagesWrap dFlex">
                                                {salesData?.files?.map(file => {
                                                    return (
                                                        <div className="prescriptionImage">
                                                            <a
                                                                download={
                                                                    file?.name
                                                                }
                                                                href={file.url}
                                                                title={
                                                                    file?.name
                                                                }>
                                                                <img
                                                                    src={
                                                                        file?.url
                                                                    }
                                                                    alt={
                                                                        file?.name
                                                                    }
                                                                />
                                                            </a>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="viewProductHeader">
                                            <table
                                                className="table productPrice pt-4"
                                                cellPadding={2}
                                                cellSpacing={2}>
                                                <tbody>
                                                    <tr>
                                                        <td>Amount (BDT)</td>
                                                        <td align="right">
                                                            {salesData?.amount ||
                                                                paymentDetails?.[
                                                                    PAYMENT_DETAILS_FIELDS
                                                                        .AMOUNT
                                                                ]}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>VAT</td>
                                                        <td align="right">
                                                            {salesData?.vat ||
                                                                0}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount </td>
                                                        <td align="right">
                                                            {salesData?.discount ||
                                                                0}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="strong">
                                                            Total (BDT)
                                                        </td>
                                                        <td
                                                            className="strong"
                                                            align="right">
                                                            {salesData?.total_amount ||
                                                                paymentDetails?.[
                                                                    PAYMENT_DETAILS_FIELDS
                                                                        .TOTAL_AMOUNT
                                                                ]}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="disclaimerNotes">
                                            <div className="disclaimerNotesHeading">
                                                Payment Method
                                            </div>
                                            <div className="disclaimerNotesDesc">
                                                Cash on Delivery
                                            </div>
                                        </div> */}
                                        {/* <div className="viewProductHeader">
                                            <div className="formGroup formFloating">
                                                <ReactSelect
                                                    className="formSelect reactSelect"
                                                    id="floatingSelect"
                                                    value={
                                                        selectedDeliveryPartner
                                                    }
                                                    options={
                                                        deliveryPartnerOptionList
                                                    }
                                                    onChange={option =>
                                                        setSelectedDeliveryPartner(
                                                            option
                                                        )
                                                    }
                                                    getOptionLabel={option =>
                                                        option.name
                                                    }
                                                    placeholder="Select Delivery Partner"
                                                    styles={{
                                                        control: base => ({
                                                            border: 0,
                                                            display: 'flex',
                                                            paddingTop: '20px'
                                                        }),
                                                        option: (
                                                            provided,
                                                            state
                                                        ) => ({
                                                            ...provided,
                                                            backgroundColor: state.isFocused
                                                                ? 'rgb(222, 235, 255)'
                                                                : state.isSelected
                                                                ? '#FFF'
                                                                : 'inherit',
                                                            color: '#212529',
                                                            '&:hover': {
                                                                backgroundColor:
                                                                    state.isSelected
                                                                        ? '#DEEBFF'
                                                                        : 'rgb(222, 235, 255)'
                                                            }
                                                        })
                                                    }}
                                                    isClearable
                                                />
                                                <label for="floatingSelect">
                                                    Delivery Partner
                                                </label>
                                                {isFieldErrorVisible &&
                                                    !selectedDeliveryPartner && (
                                                        <div className="invalidFeedback">
                                                            Please choose
                                                            Delivery Partner.
                                                        </div>
                                                    )}
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                            {salesData?.status ===
                                PRESCRIPTION_ORDER_MILESTONE.PRESCRIPTION_PENDING_ON_CUSTOMER && (
                                <div className="mask">
                                    <div className="maskData dFlex aiCenter flexColumn">
                                        <PendingImage />
                                        <div className="">
                                            Order confirmation pending from
                                            customer
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/** <div className="wizardFieldset">
                        <div className="addedItemListWrap">
                            <ul className="addedItemList">
                                <li className="addedItem">
                                    <div className="dFlex jcSpaceBetween aiCenter mb-4">
                                        <div className="addedItemName">
                                            Product 1
                                        </div>
                                        <div className="addedItemAction">
                                            <TrashIcon />
                                            <ArrowDown />
                                        </div>
                                    </div>
                                    <form
                                        onSubmit={e => e.preventDefault()}
                                        className="mt-5">
                                        <div className="formGroup formFloating">
                                            <input
                                                type="text"
                                                className="formControl"
                                                id="text"
                                                placeholder="Product Barcode/QR Code"
                                                required
                                            />
                                            <label for="address">
                                                Product Barcode/QR Code
                                            </label>
                                            {false && (
                                                <div className="invalidFeedback">
                                                    Please enter a valid Product
                                                    Barcode/QR Code.
                                                </div>
                                            )}
                                        </div>
                                        <div className="formGroup formFloating">
                                            <select
                                                className="formSelect"
                                                id="floatingSelect">
                                                <option value="1">
                                                    Product One
                                                </option>
                                                <option value="2">
                                                    Product Two
                                                </option>
                                            </select>
                                            <label for="floatingSelect">
                                                Name Name
                                            </label>
                                            {false && (
                                                <div className="invalidFeedback">
                                                    Please choose Name Name.
                                                </div>
                                            )}
                                        </div>
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating fgrow1">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="Quantity"
                                                    placeholder="Quantity"
                                                    required
                                                />
                                                <label for="Quantity">
                                                    Quantity
                                                </label>
                                                <div className="invalidFeedback">
                                                    Please select a valid
                                                    Quantity.
                                                </div>
                                            </div>
                                            <div className="formGroup formFloating ml-4 fgrow1">
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="Dose"
                                                    placeholder="Dose"
                                                    required
                                                />
                                                <label for="Dose">Dose</label>
                                                <div className="invalidFeedback">
                                                    Please select a valid Dose.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formInline dFlex flexNowrap">
                                            <div className="formGroup formFloating formInputText fgrow1">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        BDT
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="email"
                                                    placeholder="Cost/Unit"
                                                    required
                                                />
                                                <label for="address">
                                                    Cost/Unit
                                                </label>
                                                {false && (
                                                    <div className="invalidFeedback">
                                                        Please enter Cost/Unit.
                                                    </div>
                                                )}
                                            </div>
                                            <div className="formGroup formFloating formInputText ml-4 fgrow1">
                                                <div className="preInputGroup backColor">
                                                    <div className="preInputText">
                                                        BDT
                                                    </div>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="formControl"
                                                    id="email"
                                                    placeholder="Rate/Unit"
                                                    required
                                                />
                                                <label for="address">
                                                    Rate/Unit
                                                </label>
                                                {false && (
                                                    <div className="invalidFeedback">
                                                        Please enter Rate/Unit.
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </li>
                            </ul>
                        </div>
                        <div className="addItemButton">
                            <button className="btn btnPrimary dFlex">
                                <AddIcon />
                            </button>
                        </div>
                                                </div> */}
                        {/* <div className="wizardFieldset">
                        <div className="salesOnline">
                            <div className="viewProductWrap">
                                <div className="viewProductHeader">
                                    <div className="headerRow dFlex jcSpaceBetween aiCenter">
                                        <div className="ProductUniqueTag">
                                            765432456789
                                        </div>
                                        <div className="headerRowRight dFlex flexColumn">
                                            25 Jan 2022 <small>8:00 PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="viewProductHeader">
                                    <div className="contactDetailsRow dFlex">
                                        <div className="productSupplierName">
                                            Customer Name
                                        </div>
                                        <div className="productSupplierName">
                                            +880 95435678976
                                        </div>
                                    </div>
                                    <div className="productSupplierAddress dFlex aiCenter">
                                        <LocationIcon className="icon" /> Lorem
                                        ipsum dose amet consectetur. Bibendum{' '}
                                    </div>
                                </div>
                                <div className="prescriptionImages">
                                    <div className="prescriptionImagesWrap dFlex">
                                        <div className="prescriptionImage">
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/86/Solid_grey.svg"
                                                alt="img"
                                            />
                                        </div>
                                        <div className="prescriptionImage">
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/86/Solid_grey.svg"
                                                alt="img"
                                            />
                                        </div>
                                        <div className="prescriptionImage">
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/86/Solid_grey.svg"
                                                alt="img"
                                            />
                                        </div>
                                        <div className="prescriptionImage">
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/86/Solid_grey.svg"
                                                alt="img"
                                            />
                                        </div>
                                        <div className="prescriptionImage">
                                            <img
                                                src="https://upload.wikimedia.org/wikipedia/commons/8/86/Solid_grey.svg"
                                                alt="img"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="disclaimerNotes">
                                    <div className="disclaimerNotesHeading">
                                        Notes
                                    </div>
                                    <div className="disclaimerNotesDesc">
                                        Lorem ipsum dolor sit amet consectetur.
                                        Quis ornare quam semper ullamcorper
                                        sodales amet mollis sed. Sit risus
                                        mauris velit cursus elementum nisl sit
                                        arcu. Tincidunt posuere diam sit amet
                                        pharetra sapien.
                                    </div>
                                </div>
                                <div className="viewProductHeader">
                                    <table
                                        className="table productPrice pt-4"
                                        cellPadding={2}
                                        cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td>Amount (BDT)</td>
                                                <td align="right">20,000</td>
                                            </tr>
                                            <tr>
                                                <td>VAT (2%)</td>
                                                <td align="right">20,000</td>
                                            </tr>
                                            <tr>
                                                <td>Discount (10%)</td>
                                                <td align="right">20,000</td>
                                            </tr>
                                            <tr>
                                                <td className="strong">
                                                    Total (BDT)
                                                </td>
                                                <td
                                                    className="strong"
                                                    align="right">
                                                    20,000
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="disclaimerNotes">
                                    <div className="disclaimerNotesHeading">
                                        Payment Method
                                    </div>
                                    <div className="disclaimerNotesDesc">
                                        Cash on Delivery
                                    </div>
                                </div>
                                <div className="viewProductHeader">
                                    <div className="formGroup formFloating">
                                        <select
                                            className="formSelect"
                                            id="floatingSelect">
                                            <option value="1">
                                                Delivery Partner One
                                            </option>
                                            <option value="2">
                                                Delivery Partner Two
                                            </option>
                                        </select>
                                        <label for="floatingSelect">
                                            Delivery Partner
                                        </label>
                                        {false && (
                                            <div className="invalidFeedback">
                                                Please choose Delivery Partner.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mask dFlex aiCenter flexColumn">
                                <PendingImage />
                                <div className="">
                                    Order confirmation pending from customer
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </SideDrawer>
                )}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        alert: state.alertReducer.alert,
        salesData: state?.appDataReducer?.salesData,
        loginData: state.loginDataReducer?.loginData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createSalesAlertAction: payload =>
            dispatch(createSalesAlertAction(payload)),
        holdSalesAlertAction: payload =>
            dispatch(holdSalesAlertAction(payload)),
        updateSalesAlertAction: payload =>
            dispatch(updateSalesAlertAction(payload)),
        onlineSalesOrderConfirmationAlertAction: payload =>
            dispatch(onlineSalesOrderConfirmationAlertAction(payload)),
        onlineSalesOrderCancellationAlertAction: payload =>
            dispatch(onlineSalesOrderCancellationAlertAction(payload)),
        dispatchSalesDataAction: payload => dispatch(salesDataAction(payload)),
        dispatchNewSalesDataAction: payload =>
            dispatch(newSalesDataAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sales)
