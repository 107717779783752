import { Types } from './types'

export const loginDataAction = data => {
    return {
        type: Types.LOGIN_DATA,
        payload: data
    }
}

export const updateBranchDataAction = data => {
    return {
        type: Types.UPDATE_BRANCH,
        payload: data
    }
}

export const logoutAction = () => {
    return {
        type: Types.LOGOUT
    }
}

export const activeHomeDetailNavigation = status => {
    return {
        type: Types.ACTIVE_HOME_DETAIL_NAVIGATION,
        payload: status
    }
}

export const activeProfileDetailNavigation = status => {
    return {
        type: Types.ACTIVE_PROFILE_DETAIL_NAVIGATION,
        payload: status
    }
}

export const notificationActiveAction = status => {
    return {
        type: Types.IS_NOTIFICATION_ACTIVE,
        payload: status
    }
}

export const addProductReceivingAlertAction = status => {
    return {
        type: Types.ADD_PRODUCT_RECEIVING_ALERT,
        payload: status
    }
}

export const removeProductReceivingAlertAction = status => {
    return {
        type: Types.ADD_PRODUCT_RECEIVING_ALERT,
        payload: status
    }
}

export const addUserAlertAction = status => {
    return {
        type: Types.ADD_USER_ALERT,
        payload: status
    }
}

export const deleteUserAlertAction = status => {
    return {
        type: Types.DELETE_USER_ALERT,
        payload: status
    }
}

export const addBranchAlertAction = status => {
    return {
        type: Types.ADD_BRANCH_ALERT,
        payload: status
    }
}

export const updateBranchAlertAction = status => {
    return {
        type: Types.UPDATE_BRANCH_ALERT,
        payload: status
    }
}

export const addCustomerAlertAction = status => {
    return {
        type: Types.ADD_CUSTOMER_ALERT,
        payload: status
    }
}

export const addDamageLossAlertAction = status => {
    return {
        type: Types.ADD_DAMAGE_LOSS_ALERT,
        payload: status
    }
}

export const createSalesAlertAction = status => {
    return {
        type: Types.CREATE_SALES_ALERT,
        payload: status
    }
}

export const holdSalesAlertAction = status => {
    return {
        type: Types.HOLD_SALES_ALERT,
        payload: status
    }
}

export const updateSalesAlertAction = status => {
    return {
        type: Types.UPDATE_SALES_ALERT,
        payload: status
    }
}

export const updateSalesByCustomerAlertAction = status => {
    return {
        type: Types.UPDATE_SALES_BY_CUSTOMER_ALERT,
        payload: status
    }
}

export const createExchangeReturnAlertAction = status => {
    return {
        type: Types.CREATE_EXCHANGE_RETURN_ALERT,
        payload: status
    }
}

export const addQueryAlertAction = status => {
    return {
        type: Types.ADD_QUERY_ALERT,
        payload: status
    }
}

export const addReminderAlertAction = status => {
    return {
        type: Types.ADD_REMINDER_ALERT,
        payload: status
    }
}

export const deleteReminderAlertAction = status => {
    return {
        type: Types.DELETE_REMINDER_ALERT,
        payload: status
    }
}

export const addDeliveryPersonAlertAction = status => {
    return {
        type: Types.ADD_DELIVERY_PERSON_ALERT,
        payload: status
    }
}

export const onlineSalesOrderConfirmationAlertAction = status => {
    return {
        type: Types.ONLINE_SALES_ORDER_CONFIRMATION_ALERT,
        payload: status
    }
}

export const onlineSalesOrderCancellationAlertAction = status => {
    return {
        type: Types.ONLINE_SALES_ORDER_CANCELLATION_ALERT,
        payload: status
    }
}

export const salesDataAction = status => {
    return {
        type: Types.SALES_DATA,
        payload: status
    }
}

export const newSalesDataAction = status => {
    return {
        type: Types.NEW_SALES_DATA,
        payload: status
    }
}

export const updateSalesDataAction = status => {
    return {
        type: Types.UPDATE_SALES_DATA,
        payload: status
    }
}

export const newSalesAlertAction = status => {
    return {
        type: Types.NEW_SALES_ALERT,
        payload: status
    }
}

export const newOrderBookAction = status => {
    return {
        type: Types.ADD_ORDER_BOOK_ALERT,
        payload: status
    }
}

export const addCampaignDiscountAction = status => {
    return {
        type: Types.ADD_CAMPAIGN_DISCOUNT_ALERT,
        payload: status
    }
}

export const updateCampaignDiscountAction = status => {
    return {
        type: Types.UPDATE_CAMPAIGN_DISCOUNT_ALERT,
        payload: status
    }
}

export const addSingleProductDiscountAction = status => {
    return {
        type: Types.ADD_SINGLE_PRODUCT_DISCOUNT_ALERT,
        payload: status
    }
}
