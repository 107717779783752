import { Types } from '../actions/types'
import initialState from '../store/initial.state'

export const alertReducer = (state = initialState.alertReducer, action) => {
    switch (action.type) {
        case Types.ADD_PRODUCT_RECEIVING_ALERT: {
            let addProductReceivingAlert = action.payload
            return {
                ...state,
                alert: {
                    addProductReceivingAlert: addProductReceivingAlert
                }
            }
        }
        case Types.ADD_USER_ALERT: {
            let addUserAlert = action.payload
            return {
                ...state,
                alert: {
                    addUserAlert: addUserAlert
                }
            }
        }
        case Types.DELETE_USER_ALERT: {
            let deleteUserAlert = action.payload
            return {
                ...state,
                alert: {
                    deleteUserAlert: deleteUserAlert
                }
            }
        }
        case Types.ADD_BRANCH_ALERT: {
            let addBranchAlert = action.payload
            return {
                ...state,
                alert: {
                    addBranchAlert: addBranchAlert
                }
            }
        }
        case Types.UPDATE_BRANCH_ALERT: {
            let updateBranchAlert = action.payload
            return {
                ...state,
                alert: {
                    updateBranchAlert: updateBranchAlert
                }
            }
        }
        case Types.ADD_CUSTOMER_ALERT: {
            let addCustomerAlert = action.payload
            return {
                ...state,
                alert: {
                    addCustomerAlert: addCustomerAlert
                }
            }
        }
        case Types.ADD_DAMAGE_LOSS_ALERT: {
            let addDamageLossAlert = action.payload
            return {
                ...state,
                alert: {
                    addDamageLossAlert: addDamageLossAlert
                }
            }
        }
        case Types.CREATE_SALES_ALERT: {
            let createSalesAlert = action.payload
            return {
                ...state,
                alert: {
                    createSalesAlert: createSalesAlert
                }
            }
        }
        case Types.HOLD_SALES_ALERT: {
            let holdSalesAlert = action.payload
            return {
                ...state,
                alert: {
                    holdSalesAlert: holdSalesAlert
                }
            }
        }
        case Types.UPDATE_SALES_ALERT: {
            let updateSalesAlert = action.payload
            return {
                ...state,
                alert: {
                    updateSalesAlert: updateSalesAlert
                }
            }
        }
        case Types.UPDATE_SALES_BY_CUSTOMER_ALERT: {
            let updateSalesByCustomerAlert = action.payload
            return {
                ...state,
                alert: {
                    updateSalesByCustomerAlert: updateSalesByCustomerAlert
                }
            }
        }
        case Types.CREATE_EXCHANGE_RETURN_ALERT: {
            let createExchangeReturnAlert = action.payload
            return {
                ...state,
                alert: {
                    createExchangeReturnAlert: createExchangeReturnAlert
                }
            }
        }
        case Types.ADD_QUERY_ALERT: {
            let addQueryAlert = action.payload
            return {
                ...state,
                alert: {
                    addQueryAlert: addQueryAlert
                }
            }
        }
        case Types.ADD_REMINDER_ALERT: {
            let addReminderAlert = action.payload
            return {
                ...state,
                alert: {
                    addReminderAlert: addReminderAlert
                }
            }
        }
        case Types.DELETE_REMINDER_ALERT: {
            let deleteReminderAlert = action.payload
            return {
                ...state,
                alert: {
                    deleteReminderAlert: deleteReminderAlert
                }
            }
        }
        case Types.ADD_DELIVERY_PERSON_ALERT: {
            let addDeliveryPersonAlert = action.payload
            return {
                ...state,
                alert: {
                    addDeliveryPersonAlert: addDeliveryPersonAlert
                }
            }
        }
        case Types.ONLINE_SALES_ORDER_CONFIRMATION_ALERT: {
            let onlineSalesConfirmationAlert = action.payload
            return {
                ...state,
                alert: {
                    onlineSalesConfirmationAlert: onlineSalesConfirmationAlert
                }
            }
        }
        case Types.ONLINE_SALES_ORDER_CANCELLATION_ALERT: {
            let onlineSalesCancellationAlert = action.payload
            return {
                ...state,
                alert: {
                    onlineSalesCancellationAlert: onlineSalesCancellationAlert
                }
            }
        }
        case Types.ADD_ORDER_BOOK_ALERT: {
            let addOrderBookAlert = action.payload
            return {
                ...state,
                alert: {
                    addOrderBookAlert: addOrderBookAlert
                }
            }
        }
        case Types.ADD_SINGLE_PRODUCT_DISCOUNT_ALERT: {
            let addSingleProductDiscountAlert = action.payload
            return {
                ...state,
                alert: {
                    addSingleProductDiscountAlert: addSingleProductDiscountAlert
                }
            }
        }
        case Types.ADD_CAMPAIGN_DISCOUNT_ALERT: {
            let addCampaignDiscountAlert = action.payload
            return {
                ...state,
                alert: {
                    addCampaignDiscountAlert: addCampaignDiscountAlert
                }
            }
        }
        case Types.UPDATE_CAMPAIGN_DISCOUNT_ALERT: {
            let updateCampaignDiscountAlert = action.payload
            return {
                ...state,
                alert: {
                    updateCampaignDiscountAlert: updateCampaignDiscountAlert
                }
            }
        }
        case Types.NEW_SALES_ALERT: {
            let newSalesAlert = action.payload
            return {
                ...state,
                alert: {
                    newSalesAlert: newSalesAlert
                }
            }
        }

        default:
            return state
    }
}
