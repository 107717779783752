export const Types = {
    ACTIVE_HOME_DETAIL_NAVIGATION: 'ACTIVE_HOME_DETAIL_NAVIGATION',
    ACTIVE_PROFILE_DETAIL_NAVIGATION: 'ACTIVE_PROFILE_DETAIL_NAVIGATION',
    ADD_PRODUCT_RECEIVING_ALERT: 'ADD_PRODUCT_RECEIVING_ALERT',
    ADD_USER_ALERT: 'ADD_USER_ALERT',
    DELETE_USER_ALERT: 'DELETE_USER_ALERT',
    ADD_CUSTOMER_ALERT: 'ADD_CUSTOMER_ALERT',
    ADD_BRANCH_ALERT: 'ADD_BRANCH_ALERT',
    UPDATE_BRANCH_ALERT: 'UPDATE_BRANCH_ALERT',
    ADD_DAMAGE_LOSS_ALERT: 'ADD_DAMAGE_LOSS_ALERT',
    CREATE_SALES_ALERT: 'CREATE_SALES_ALERT',
    HOLD_SALES_ALERT: 'HOLD_SALES_ALERT',
    UPDATE_SALES_ALERT: 'UPDATE_SALES_ALERT',
    UPDATE_SALES_BY_CUSTOMER_ALERT: 'UPDATE_SALES_BY_CUSTOMER_ALERT',
    CREATE_EXCHANGE_RETURN_ALERT: 'CREATE_EXCHANGE_RETURN_ALERT',
    LOGIN_DATA: 'LOGIN_DATA',
    UPDATE_BRANCH: 'UPDATE_BRANCH',
    LOGOUT: 'LOGOUT',
    ADD_QUERY_ALERT: 'ADD_QUERY_ALERT',
    ADD_REMINDER_ALERT: 'ADD_REMINDER_ALERT',
    DELETE_REMINDER_ALERT: 'DELETE_REMINDER_ALERT',
    ADD_DELIVERY_PERSON_ALERT: 'ADD_DELIVERY_PERSON_ALERT',
    ONLINE_SALES_ORDER_CONFIRMATION_ALERT:
        'ONLINE_SALES_ORDER_CONFIRMATION_ALERT',
    ONLINE_SALES_ORDER_CANCELLATION_ALERT:
        'ONLINE_SALES_ORDER_CANCELLATION_ALERT',
    SALES_DATA: 'SALES_DATA',
    NEW_SALES_DATA: 'NEW_SALES_DATA',
    UPDATE_SALES_DATA: 'UPDATE_SALES_DATA',
    NEW_SALES_ALERT: 'NEW_SALES_ALERT',
    EXCHANGE_RETURN_DATA: 'EXCHANGE_RETURN_DATA',
    NEW_EXCHANGE_RETURN_DATA: 'NEW_EXCHANGE_RETURN_DATA',
    ADD_ORDER_BOOK_ALERT: 'ADD_ORDER_BOOK_ALERT',
    ADD_SINGLE_PRODUCT_DISCOUNT_ALERT: 'ADD_SINGLE_PRODUCT_DISCOUNT_ALERT',
    ADD_CAMPAIGN_DISCOUNT_ALERT: 'ADD_CAMPAIGN_DISCOUNT_ALERT',
    UPDATE_CAMPAIGN_DISCOUNT_ALERT: 'UPDATE_CAMPAIGN_DISCOUNT_ALERT',
    IS_NOTIFICATION_ACTIVE: 'IS_NOTIFICATION_ACTIVE'
}
