import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import Barcode from 'react-barcode'
// import { convert } from 'convert-svg-to-png';

import './ProductReceiving.scss'
import {
    PAGE_CONST,
    PRODUCT_CATEGORY,
    PRODUCT_DETAIL_FIELDS,
    PRODUCT_ORIGIN,
    PRODUCT_STRIP_MEDICAL_CATEGORY
} from '../../common/constant/page-constant'
import SideDrawer from '../Common/SideDrawer/SideDrawer'
import BarcodeIcon from '../../assets/BarcodeIcon'
import AttachIcon from '../../assets/AttachIcon'
import CloseIcon from '../../assets/CloseIcon'
import CheckIcon from '../../assets/CheckIcon'
import TrashIcon from '../../assets/TrashIcon'
import ZXingScanner from '../../components/ZXingScanner/ZXingScanner'
import BarcodeScanner from '../../components/ZXingScanner/BarcodeScanner'
import {
    HEADER_CONFIG,
    HEADER_CONST
} from '../../common/constant/common-constant'
import { redirect } from '../../common/utils/redirectionUtils'
import useCheckMobileScreen from '../../common/hooks/use-check-mobile-screen-hook'
import MobileHeader from '../Common/Header/MobileHeader'

function AddProduct(props) {
    const navigate = useNavigate()
    const svgRef = useRef()

    const [viewDrawer, setViewDrawer] = useState(false)
    const [open, setOpen] = useState(false)
    const [barcodeValue, setBarcodeValue] = useState(undefined)
    const [selectedFile, setSelectedFile] = useState(null)
    const [isScannerVisible, setIsScannerVisible] = useState(false)
    const [scannedResult, setScannedResult] = useState('')
    // const [headerOptions, setHeaderOptions] = useState({
    //     [HEADER_CONFIG.IS_BACK_VISIBLE]: true,
    //     [HEADER_CONFIG.TITLE]: HEADER_CONST.ADD_PRODUCT_RECEIVING,
    //     [HEADER_CONFIG.HANDLE_BACK_FUNC]: () => setOpen(false)
    // })
    const isMobile = useCheckMobileScreen()

    const handleDrawerToggle = () => {
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleScan = result => {
        if (result) {
            console.log('result in handleScan: ', result)
            // setScannedResult(result)
            props.handleProductUpdate(
                props.product?.id,
                PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE,
                result
            )
            setIsScannerVisible(false) // Hide the scanner after a successful scan
        }
    }

    const handleError = err => {
        console.error(err)
    }

    const barcodeOptions = {
        format: 'auto'
        // displayValue: true
    }

    const handleGenerateBarcode = () => {
        let bValue = Date.now() + Math.random()

        setBarcodeValue(bValue)
        props.handleProductUpdate(
            props.product?.id,
            PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE,
            bValue
        )
        setViewDrawer(!viewDrawer)
        setOpen(!open)
    }

    const handleDownloadBarcodeClick = async () => {
        const svg = svgRef.current.innerHTML
        const blob = new Blob([svg], { type: 'image/svg+xml' })

        const objectUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = objectUrl
        link.download = `myimage.svg`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
    }

    return (
        <>
            {/* {isMobile && <MobileHeader headerOptions={headerOptions} />} */}
            <form className="mt-5" onSubmit={e => e.preventDefault()}>
                {props?.clearProduct && (
                    <div className="addedProductAction dFlex jcSpaceBetween aiCenter">
                        <div></div>
                        <div
                            className="trashIcon"
                            onClick={() => props?.clearProduct()}>
                            <TrashIcon />
                        </div>
                    </div>
                )}
                {/** product Category - pharma/non-pharma */}
                <div className="formSection dFlex mb-5">
                    <div class="formCheck">
                        <input
                            class="formCheckInput"
                            type="radio"
                            name="flexRadioDefault"
                            id="Pharmaceutical" // TODO: upgrade to all caps and move to constants
                            disabled={props.isViewOnlyProduct}
                            checked={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY
                                ] === PRODUCT_CATEGORY.PHARMACEUTICAL
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY,
                                    PRODUCT_CATEGORY.PHARMACEUTICAL
                                )
                            }
                        />
                        <label class="formCheckLabel" htmlFor="Pharmaceutical">
                            Pharmaceutical
                        </label>
                    </div>
                    <div class="formCheck">
                        <input
                            class="formCheckInput"
                            type="radio"
                            name="flexRadioDefault"
                            disabled={props.isViewOnlyProduct}
                            id="nPharmaceutical"
                            checked={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY
                                ] === PRODUCT_CATEGORY.NON_PHARMACEUTICAL
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY,
                                    PRODUCT_CATEGORY.NON_PHARMACEUTICAL
                                )
                            }
                        />
                        <label class="formCheckLabel" htmlFor="nPharmaceutical">
                            Non-Pharmaceutical
                        </label>
                    </div>
                    {props.isFieldErrorVisible &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY
                        ] && (
                            <div class="invalidFeedback">
                                Please select Product Category.
                            </div>
                        )}
                </div>
                {/** product Name */}
                <div className="formGroup formFloating fgrow1 maxWidth">
                    <CreatableSelect
                        className="formSelect reactSelect"
                        id="floatingSelect"
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ] || {
                                name: props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_NAME
                                ],
                                label: props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_NAME
                                ],
                                dose: props.product?.[
                                    PRODUCT_DETAIL_FIELDS.DOSE
                                ],
                                category:
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY
                                    ]
                            } ||
                            ''
                        }
                        isDisabled={props.isViewOnlyProduct} // TODO: disable to
                        onChange={option =>
                            props.handleProductUpdate(
                                props.product?.id,
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT,
                                option
                            )
                        }
                        styles={{
                            control: base => ({
                                border: 0,
                                display: 'flex',
                                paddingTop: '20px'
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused
                                    ? 'rgb(222, 235, 255)'
                                    : state.isSelected
                                    ? '#FFF'
                                    : 'inherit',
                                color: '#212529',
                                '&:hover': {
                                    backgroundColor: state.isSelected
                                        ? '#DEEBFF'
                                        : 'rgb(222, 235, 255)'
                                }
                            })
                        }}
                        options={props.productData}
                        // formatCreateLabel={() => 'test'}
                        // noOptionsMessage={() => null}
                        // formatCreateLabel={inputValue => `Create "${inputValue}"`}
                        // isValidNewOption={() => true}
                        getOptionLabel={option => {
                            // console.log(option)
                            return option.name
                                ? `${option.name} ${
                                      option.dose ? option.dose : ''
                                  } - ${option.category ? option.category : ''}`
                                : option.label
                        }}
                        placeholder="Select Product Name"
                        isClearable
                        // menuIsOpen={true}
                        onCreateOption={newOption => {
                            console.log('New option created:', newOption)
                            props.handleProductUpdate(
                                props.product?.id,
                                PRODUCT_DETAIL_FIELDS.PRODUCT_NAME,
                                newOption
                            )
                        }}
                    />
                    <label htmlFor="floatingSelect">Product Name</label>
                    {props.isFieldErrorVisible &&
                        !props.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NAME] &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ] && (
                            <div class="invalidFeedback">
                                Please choose Product Name.
                            </div>
                        )}
                    {/* <div className="inputSuggestionsWrap">
                    <ul className="inputSuggestion dFlex">
                        <li className="suggestion">Foreign</li>
                        <li className="suggestion">Allergy & Immune System</li>
                    </ul>
                </div> */}
                </div>
                {/** Product Barcode */}
                <div className="formSection">
                    <div className="formGroup formFloating fgrow1">
                        <input
                            type="text"
                            className="formControl"
                            disabled={props.isViewOnlyProduct}
                            id="batch"
                            placeholder="Product Barcode"
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] ||
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                                ] ||
                                ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="batch">Product Barcode</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] && (
                                <div class="invalidFeedback">
                                    Please select a valid Product Barcode.
                                </div>
                            )}
                        <div
                            className="positionedIcon"
                            onClick={() => setIsScannerVisible(true)}>
                            <BarcodeIcon />
                        </div>
                        {isScannerVisible && (
                            <BarcodeScanner onScan={handleScan} />
                        )}
                    </div>
                    {!barcodeValue &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                        ] && (
                            <div
                                className="formHeading generateQR"
                                onClick={() => handleGenerateBarcode()}>
                                Generate Barcode
                            </div>
                        )}
                    {(barcodeValue ||
                        props.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE] ||
                        props.product?.[
                            PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                        ]) && (
                        <div
                            className="formHeading generateQR"
                            onClick={() => handleDrawerToggle()}>
                            View Barcode
                        </div>
                    )}
                </div>
                {/** barcode SideDrawer */}
                <SideDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    open={open}
                    downloadButton={true}
                    downloadText={'Save & Print'}
                    drawerHeading="Product Barcode"
                    handleClick={() => handleDownloadBarcodeClick()}
                    // sideDrawerFooter={
                    //     <div className='download btn btnPrimary block'
                    //         onClick={() => handleDownloadBarcodeClick()}
                    //     >
                    //         Save & Print
                    //     </div>
                    // }
                >
                    <div
                        id="barcode"
                        className="dFlex jcContentCenter aiCenter barcode">
                        <div ref={svgRef}>
                            <Barcode
                                value={
                                    barcodeValue ||
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[
                                        PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                                    ] ||
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.PRODUCT_BARCODE
                                    ]
                                }
                                options={barcodeOptions}
                            />
                        </div>
                    </div>
                </SideDrawer>
                {/** Generic Name */}
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="genericName"
                        placeholder="Generic Name"
                        disabled={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.GENERIC_NAME] ||
                            props.isViewOnlyProduct
                        }
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.GENERIC_NAME] ||
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.GENERIC_NAME
                            ] ||
                            ''
                        }
                        onChange={e =>
                            props.handleProductUpdate(
                                props.product?.id,
                                PRODUCT_DETAIL_FIELDS.GENERIC_NAME,
                                e.target.value
                            )
                        }
                        required
                    />
                    <label htmlFor="bonusquantity">Generic Name</label>
                    {props.isFieldErrorVisible &&
                        !props.product?.[PRODUCT_DETAIL_FIELDS.GENERIC_NAME] &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ]?.[PRODUCT_DETAIL_FIELDS.GENERIC_NAME] && (
                            <div class="invalidFeedback">
                                Please select a valid Generic Name.
                            </div>
                        )}
                </div>
                {/** product image */}
                {!props.isViewOnlyProduct && (
                    <>
                        <div className="formGroup typeFile">
                            <div className="beforeUpload">
                                <div className="inputHeading">
                                    Product Image<span>*</span>
                                </div>
                                {!props.product?.[
                                    PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE]
                                    ?.name &&
                                    !props.product?.[
                                        PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE
                                    ]?.name && (
                                        <div className="customFile">
                                            <input
                                                type="file"
                                                className="fileInput"
                                                id="productImage"
                                                aria-describedby="productImage"
                                                onChange={e => {
                                                    e.preventDefault()
                                                    props.handleProductUpdate(
                                                        props.product?.id,
                                                        PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE,
                                                        e.target.files[0]
                                                    )
                                                }}
                                            />
                                            <label
                                                className="fileLabel"
                                                htmlFor="productImage">
                                                <AttachIcon />
                                            </label>
                                        </div>
                                    )}
                            </div>
                            {(!!props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE]?.name ||
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE
                                ]?.name) && (
                                <>
                                    <div className="uploadFile dFlex jcSpaceBetween">
                                        <div className="">
                                            <div className="uploadFileName">
                                                {props.product?.[
                                                    PRODUCT_DETAIL_FIELDS
                                                        .SELECTED_PRODUCT
                                                ]?.[
                                                    PRODUCT_DETAIL_FIELDS
                                                        .PRODUCT_IMAGE
                                                ]?.name ||
                                                    props.product?.[
                                                        PRODUCT_DETAIL_FIELDS
                                                            .PRODUCT_IMAGE
                                                    ]?.name ||
                                                    ''}
                                            </div>
                                            <div className="uploadFileProgress">
                                                <div className="progress progress-moved">
                                                    <div className="animation progress-bar"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="cancelUpload"
                                            onClick={() => {
                                                props.handleProductUpdate(
                                                    props.product?.id,
                                                    PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE,
                                                    undefined
                                                )
                                            }}>
                                            <CloseIcon />
                                            <div className="cancelUpload">
                                                <CheckIcon />{' '}
                                            </div>
                                        </div>
                                    </div>
                                    {!props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE]
                                        ?.name &&
                                        !props.product?.[
                                            PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE
                                        ] && (
                                            <div className="invalidFeedback">
                                                Please choose a valid file
                                            </div>
                                        )}
                                </>
                            )}
                            {props.isFieldErrorVisible &&
                                !props.product?.[
                                    PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE]
                                    ?.name &&
                                !props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_IMAGE
                                ]?.name && (
                                    <div class="invalidFeedback">
                                        Please upload an image.
                                    </div>
                                )}
                        </div>
                    </>
                )}
                {/** medical category */}
                <div className="formGroup formFloating fgrow1 maxWidth">
                    {/* {props.product?.[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY]} */}
                    <Select
                        className="formSelect reactSelect"
                        id="floatingSelect"
                        isDisabled={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY] ||
                            props.isViewOnlyProduct
                        }
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY]
                                ? {
                                      label: props.product?.[
                                          PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                      ]?.[
                                          PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY
                                      ]
                                  }
                                : {
                                      label: props.product?.[
                                          PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY
                                      ]
                                  }
                        }
                        options={props.medicalCategoryList}
                        onChange={option =>
                            props.handleProductUpdate(
                                props.product?.id,
                                PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY,
                                option?.value
                            )
                        }
                        placeholder="Select Medical Category"
                        styles={{
                            control: base => ({
                                border: 0,
                                display: 'flex',
                                paddingTop: '20px'
                            }),
                            option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused
                                    ? 'rgb(222, 235, 255)'
                                    : state.isSelected
                                    ? '#FFF'
                                    : 'inherit',
                                color: '#212529',
                                '&:hover': {
                                    backgroundColor: state.isSelected
                                        ? '#DEEBFF'
                                        : 'rgb(222, 235, 255)'
                                }
                            })
                        }}
                        getOptionLabel={option => option.label}
                        isClearable
                    />
                    <label htmlFor="floatingSelect">Medical Category</label>
                    {props.isFieldErrorVisible &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ]?.[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY] &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY
                        ] && (
                            <div class="invalidFeedback">
                                Please choose Medical Category.
                            </div>
                        )}
                </div>
                {/** PRODUCT origin - Foreign. Local */}
                {props.product?.[PRODUCT_DETAIL_FIELDS.PRODUCT_CATEGORY] !==
                    PRODUCT_CATEGORY.NON_PHARMACEUTICAL && (
                    <div className="formSection dFlex mb-5">
                        <div class="formCheck">
                            <input
                                class="formCheckInput"
                                type="radio"
                                name="flexRadioOrigin"
                                id="Origin"
                                disabled={
                                    !!props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[PRODUCT_DETAIL_FIELDS.ORIGIN] ||
                                    props.isViewOnlyProduct
                                }
                                checked={
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[PRODUCT_DETAIL_FIELDS.ORIGIN] ===
                                        PRODUCT_ORIGIN.FOREIGN ||
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.ORIGIN
                                    ] === PRODUCT_ORIGIN.FOREIGN
                                }
                                onChange={e =>
                                    props.handleProductUpdate(
                                        props.product?.id,
                                        PRODUCT_DETAIL_FIELDS.ORIGIN,
                                        PRODUCT_ORIGIN.FOREIGN
                                    )
                                }
                            />
                            <label class="formCheckLabel" htmlFor="Origin">
                                Foreign
                            </label>
                        </div>
                        <div class="formCheck">
                            <input
                                class="formCheckInput"
                                type="radio"
                                name="flexRadioOrigin"
                                id="nOrigin"
                                disabled={
                                    !!props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[PRODUCT_DETAIL_FIELDS.ORIGIN] ||
                                    props.isViewOnlyProduct
                                }
                                checked={
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[PRODUCT_DETAIL_FIELDS.ORIGIN] ===
                                        PRODUCT_ORIGIN.LOCAL ||
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.ORIGIN
                                    ] === PRODUCT_ORIGIN.LOCAL
                                }
                                onChange={e =>
                                    props.handleProductUpdate(
                                        props.product?.id,
                                        PRODUCT_DETAIL_FIELDS.ORIGIN,
                                        PRODUCT_ORIGIN.LOCAL
                                    )
                                }
                            />
                            <label class="formCheckLabel" htmlFor="nOrigin">
                                Local
                            </label>
                        </div>
                        {props.isFieldErrorVisible &&
                            !props.product?.[PRODUCT_DETAIL_FIELDS.ORIGIN] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.ORIGIN] && (
                                <div class="invalidFeedback">
                                    Please select Product Origin.
                                </div>
                            )}
                    </div>
                )}
                {/***Prescription Required */}
                <div className="formGroup">
                    <div>
                        <label class="formCheckLabel" htmlFor="Type">
                            Prescription Required?
                        </label>
                    </div>
                    <div className="formSection dFlex mb-5">
                        <div class="formCheck">
                            <input
                                class="formCheckInput"
                                type="radio"
                                name="flexRadioType"
                                id="Type"
                                disabled={
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .PRESCRIPTION_REQUIRED
                                    ] || props.isViewOnlyProduct
                                }
                                checked={
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .PRESCRIPTION_REQUIRED
                                    ] === 'YES' ||
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .PRESCRIPTION_REQUIRED
                                    ] === 'YES'
                                }
                                onChange={e =>
                                    props.handleProductUpdate(
                                        props.product?.id,
                                        PRODUCT_DETAIL_FIELDS.PRESCRIPTION_REQUIRED,
                                        'YES'
                                    )
                                }
                            />
                            <label class="formCheckLabel" htmlFor="Type">
                                Yes
                            </label>
                        </div>
                        <div class="formCheck">
                            <input
                                class="formCheckInput"
                                type="radio"
                                name="flexRadioType"
                                id="nType"
                                disabled={
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .PRESCRIPTION_REQUIRED
                                    ]
                                }
                                checked={
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .PRESCRIPTION_REQUIRED
                                    ] === 'NO' ||
                                    props.product?.[
                                        PRODUCT_DETAIL_FIELDS
                                            .PRESCRIPTION_REQUIRED
                                    ] === 'NO'
                                }
                                onChange={e =>
                                    props.handleProductUpdate(
                                        props.product?.id,
                                        PRODUCT_DETAIL_FIELDS.PRESCRIPTION_REQUIRED,
                                        'NO'
                                    )
                                }
                            />
                            <label class="formCheckLabel" htmlFor="nType">
                                No
                            </label>
                        </div>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.PRESCRIPTION_REQUIRED
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[
                                PRODUCT_DETAIL_FIELDS.PRESCRIPTION_REQUIRED
                            ] && (
                                <div class="invalidFeedback">
                                    Please select if Prescription Required or
                                    not.
                                </div>
                            )}
                    </div>
                </div>
                {/** batch number, expiry date */}
                <div className="formInline dFlex flexNowrap">
                    <div className="formGroup formFloating fgrow1">
                        <input
                            type="text"
                            className="formControl"
                            id="batch"
                            placeholder="Batch Number"
                            // disabled={props.isViewOnlyProduct}
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.BATCH_NUMBER
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.BATCH_NUMBER,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="batch">Batch Number</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.BATCH_NUMBER
                            ] && (
                                <div class="invalidFeedback">
                                    Please select a valid Batch Number.
                                </div>
                            )}
                    </div>
                    <div className="formGroup formFloating ml-4 fgrow1">
                        <input
                            type="date"
                            className="formControl"
                            id="date"
                            placeholder="Expiry Date"
                            // disabled={props.isViewOnlyProduct}
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.EXPIRY_DATE
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.EXPIRY_DATE,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="date">Expiry Date</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.EXPIRY_DATE
                            ] && (
                                <div class="invalidFeedback">
                                    Please select a Expiry Date.
                                </div>
                            )}
                    </div>
                </div>
                {/** Size */}
                <div className="formGroup formFloating fgrow1">
                    <input
                        type="text"
                        className="formControl"
                        id="bonusquantity"
                        placeholder="Size"
                        disabled={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.DOSE] ||
                            props.isViewOnlyProduct
                        }
                        value={
                            props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.DOSE] ||
                            props.product?.[PRODUCT_DETAIL_FIELDS.DOSE] ||
                            ''
                        }
                        onChange={e =>
                            props.handleProductUpdate(
                                props.product?.id,
                                PRODUCT_DETAIL_FIELDS.DOSE,
                                e.target.value
                            )
                        }
                        required
                    />
                    <label htmlFor="bonusquantity">Size</label>
                    {props.isFieldErrorVisible &&
                        !props.product?.[PRODUCT_DETAIL_FIELDS.DOSE] &&
                        !props.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ]?.[PRODUCT_DETAIL_FIELDS.DOSE] && (
                            <div class="invalidFeedback">
                                Please select a valid Size.
                            </div>
                        )}
                </div>
                {/*** Quantity and bonus Quantity */}

                <div className="formInline dFlex flexNowrap">
                    <div className="formGroup formFloating fgrow1">
                        <input
                            type="text"
                            className="formControl"
                            id="quantity"
                            placeholder="Quantity"
                            // disabled={props.isViewOnlyProduct}
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.QUANTITY
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.QUANTITY,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="quantity">Quantity</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.QUANTITY
                            ] && (
                                <div class="invalidFeedback">
                                    Please select a valid Quantity.
                                </div>
                            )}
                    </div>
                    {/***  Med per Strip  */}
                    {(PRODUCT_STRIP_MEDICAL_CATEGORY.includes(
                        props?.product?.[
                            PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                        ]?.[PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY]
                    ) ||
                        PRODUCT_STRIP_MEDICAL_CATEGORY.includes(
                            props?.product?.[
                                PRODUCT_DETAIL_FIELDS.MEDICAL_CATEGORY
                            ]
                        )) && (
                        <div className="formInline dFlex flexNowrap">
                            <div className="formGroup formFloating ml-4 fgrow1">
                                <input
                                    type="text"
                                    className="formControl"
                                    id="medperstrips"
                                    placeholder="Med/Strips"
                                    // disabled={props.isViewOnlyProduct}
                                    value={
                                        (props.product?.[
                                            PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                        ] !== undefined
                                            ? props.product?.[
                                                  PRODUCT_DETAIL_FIELDS
                                                      .MED_PER_STRIP
                                              ]
                                            : props.product?.[
                                                  PRODUCT_DETAIL_FIELDS
                                                      .SELECTED_PRODUCT
                                              ]?.[
                                                  PRODUCT_DETAIL_FIELDS
                                                      .MED_PER_STRIP
                                              ]) || ''
                                    }
                                    // value={
                                    //     props.product?.[
                                    //         PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                    //     ] ||
                                    //     props.product?.[
                                    //         PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    //     ]?.[PRODUCT_DETAIL_FIELDS.MED_PER_STRIP] ||
                                    //     ''
                                    // }
                                    // value={
                                    //     props.product?.[
                                    //         PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                    //     ] || ''
                                    // }
                                    onChange={e =>
                                        props.handleProductUpdate(
                                            props.product?.id,
                                            PRODUCT_DETAIL_FIELDS.MED_PER_STRIP,
                                            e.target.value
                                        )
                                    }
                                    required
                                />
                                <label htmlFor="medperstrips">Med/Strips</label>
                                {props.isFieldErrorVisible &&
                                    !props.product?.[
                                        PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                    ] &&
                                    !props.product?.[
                                        PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                    ]?.[
                                        PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                    ] && (
                                        <div class="invalidFeedback">
                                            Please select a valid Med/Strips.
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                    <div className="formGroup formFloating ml-4 fgrow1">
                        <input
                            type="text"
                            className="formControl"
                            id="bonusquantity"
                            placeholder="Bonus Quantity"
                            // disabled={props.isViewOnlyProduct}
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.BONUS_QUANITY
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.BONUS_QUANITY,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="bonusquantity">Bonus Quantity</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.BONUS_QUANITY
                            ] && (
                                <div class="invalidFeedback">
                                    Please select a valid Bonus Quantity.
                                </div>
                            )}
                    </div>
                    <input
                        type="text"
                        id="noofstrips"
                        hidden
                        readOnly
                        value={
                            (props.product?.[PRODUCT_DETAIL_FIELDS.QUANTITY] ||
                                0) /
                            ((props.product?.[
                                PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                            ] !== undefined
                                ? props.product?.[
                                      PRODUCT_DETAIL_FIELDS.MED_PER_STRIP
                                  ]
                                : props.product?.[
                                      PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                  ]?.[PRODUCT_DETAIL_FIELDS.MED_PER_STRIP]) ||
                                1)
                        }
                    />
                </div>

                {/*** Product Cost and vat amount */}
                <div className="formInline dFlex flexNowrap">
                    <div className="formGroup formFloating formInputText fgrow1">
                        <div className="preInputGroup backColor">
                            <div className="preInputText">BDT</div>
                        </div>
                        <input
                            type="text"
                            className="formControl"
                            id="totalAmount"
                            placeholder="Total Price"
                            // disabled={props.isViewOnlyProduct}
                            value={
                                (props.product?.[
                                    PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT
                                ] !== undefined
                                    ? props.product?.[
                                          PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT
                                      ]
                                    : props.product?.[
                                          PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                      ]?.[
                                          PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT
                                      ]) || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.TOTAL_AMOUNT,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="amount">Total Price</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.PRODUCT_COST
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_COST] && (
                                <div class="invalidFeedback">
                                    Please enter a valid Cost.
                                </div>
                            )}
                    </div>
                    <div className="formGroup formFloating formInputText fgrow1 ml-2">
                        <div className="preInputGroup backColor">
                            <div className="preInputText">BDT</div>
                        </div>
                        <input
                            type="text"
                            className="formControl"
                            id="amount"
                            placeholder="Cost/Unit"
                            disabled={props.isViewOnlyProduct}
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_COST
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_COST,
                                    e.target.value
                                )
                            }
                            readOnly
                            required
                        />
                        <label htmlFor="amount">Cost/Unit</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.PRODUCT_COST
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_COST] && (
                                <div class="invalidFeedback">
                                    Please enter a valid Cost.
                                </div>
                            )}
                    </div>
                    <div className="formGroup formFloating formInputText fgrow1 ml-2">
                        <div className="preInputGroup backColor">
                            <div className="preInputText">%</div>
                        </div>
                        <input
                            type="text"
                            className="formControl"
                            id="vatamount"
                            placeholder="Vat %"
                            disabled={props.isViewOnlyProduct}
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="vatamount">Vat %</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.VAT_PERCENTAGE] && (
                                <div class="invalidFeedback">
                                    Please enter a valid Vat Percentage.
                                </div>
                            )}
                    </div>
                </div>
                {/** New Cost, Rate */}
                <div className="formInline dFlex flexNowrap">
                    <div className="formGroup formFloating formInputText fgrow1">
                        <div className="preInputGroup backColor">
                            <div className="preInputText">BDT</div>
                        </div>
                        <input
                            type="text"
                            className="formControl"
                            id="amount"
                            placeholder="New Cost/Unit"
                            disabled
                            value={
                                props.product?.[
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST
                                ] || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="amount">New Cost/Unit</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.PRODUCT_NEW_COST] && (
                                <div class="invalidFeedback">
                                    Please enter a valid Product New Cost.
                                </div>
                            )}
                    </div>
                    <div className="formGroup formFloating formInputText fgrow1 ml-4">
                        <div className="preInputGroup backColor">
                            <div className="preInputText">BDT</div>
                        </div>
                        <input
                            type="text"
                            className="formControl"
                            id="rate/unit"
                            placeholder="Rate/Unit"
                            disabled={props.isViewOnlyProduct}
                            value={
                                (props.product?.[
                                    PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT
                                ] !== undefined
                                    ? props.product?.[
                                          PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT
                                      ]
                                    : props.product?.[
                                          PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                                      ]?.[
                                          PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT
                                      ]) || ''
                            }
                            onChange={e =>
                                props.handleProductUpdate(
                                    props.product?.id,
                                    PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT,
                                    e.target.value
                                )
                            }
                            required
                        />
                        <label htmlFor="rate/unit">Rate/Unit</label>
                        {props.isFieldErrorVisible &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT
                            ] &&
                            !props.product?.[
                                PRODUCT_DETAIL_FIELDS.SELECTED_PRODUCT
                            ]?.[PRODUCT_DETAIL_FIELDS.RATE_PER_UNIT] && (
                                <div class="invalidFeedback">
                                    Please enter a valid Rate/Unit.
                                </div>
                            )}
                    </div>
                </div>
            </form>
        </>
    )
}

AddProduct.propTypes = {
    isFieldErrorVisible: PropTypes.bool,
    handleProductUpdate: PropTypes.func,
    product: PropTypes.object
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        // testAction: (payload) => dispatch(testAction(payload))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct)
